import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { SelectAutocompleteComponent } from 'mat-select-autocomplete';
import { AppComponent } from '../../../app.component';
import { GlobalService } from '../../../services/global.service';
import { SharedService } from '../../service/shared.service';

@Component({
  selector: 'merchant-pick-location',
  templateUrl: './merchant-pickup-location-selector.component.html',
  styles: []
})
export class MerchantPickupLocationSelectorComponent implements OnInit {

  pickupLocations: any[] = [];
  selected: any
  _merchant_id: string | number;
  _pickup_id: string | number;
  
  @ViewChild('matSelectA') multiSelect: SelectAutocompleteComponent;
  
  @Output() locationChanged = new EventEmitter();
  
  @Input() placeholder: string = 'Select Pickup Location';
  @Input() noLocationMsg: string = 'No Pickup locations available';
  @Input() autoSelect: boolean = false;
  @Input() showNoLocationMsg: boolean = false;
  @Input() showOnlyIfLengthGte2: boolean = false;

  @Input()
  set merchant_id(value: string | number) {
    this._merchant_id = value;
    if (this._merchant_id) {
      this.getPickupLocations();
    }
  }

  get merchant_id(): string | number {
    return this._merchant_id;
  }

  @Input()
  set selectedId(value: string | number) {
    this._pickup_id = value;
    this.pickupSelectionChanged(value)
  }

  get selectedId(): string | number {
    return this._pickup_id;
  }

  constructor(
    public shared: SharedService,
    public global: GlobalService,
  ) { }

  ngOnInit() {
  }

  async getPickupLocations() {

    try {
      let params: any = {};
      params.merchant_id = this._merchant_id;
      this.pickupLocations = [];
      let resp: any = await this.global.getBranches(params).toPromise();
      console.log("TCL: MerchantPickupLocationSelectorComponent -> getPickupLocations -> resp", resp)
      if (resp.status) {
        this.pickupLocations = resp.data.data;
        if (this.autoSelect && this.pickupLocations.length == 1) {
          this.selectedId = this.pickupLocations[0].id;
        }
        this.pickupSelectionChanged(this.selectedId);
      } else {
      }

    } catch (error) {
      console.log("TCL: MerchantPickupLocationSelectorComponent -> getPickupLocations -> error", error)
      this.shared.openSnackBar(error.message)

    }

  }

  pickupSelectionChanged(selected) {
    if(this.multiSelect) {

      this.multiSelect.onDisplayString = () => {
        let str = '';
        if (this.selected) {
          str = this.selected.branch_name;
        }
        return str;
      }
    };
    let oldSelected = (JSON.stringify(this.selected))
    this.selected = this.pickupLocations.find(location => location.id == selected)
    if (oldSelected == JSON.stringify(this.selected)) return
    console.log(selected);
    this.locationChanged.emit(this.selected);
    if (!this.multiSelect) return;
  }

}
