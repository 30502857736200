import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private _user;
  private _token;

  constructor() { }
  isUserLoggedIn() {
    if (sessionStorage.getItem('userId') || localStorage.getItem('userId')) {

      if ( localStorage.getItem('userId') && !sessionStorage.getItem('userId') ) {
        sessionStorage.setItem('userId', localStorage.getItem('userId'));
        sessionStorage.setItem('userMobile', localStorage.getItem('userMobile'));
        sessionStorage.setItem('userName', localStorage.getItem('userName'));
        sessionStorage.setItem('profileId', localStorage.getItem('profileId'));
        sessionStorage.setItem('merchantId', localStorage.getItem('merchantId'));
      }

      return true;
    } else {
      return false;
    }
  }

  public isMerchant() {
    if (sessionStorage.getItem('profileId') == '3') {
      return true;
    }
    return false;
  }
  public isMerchantUser() {
    if (sessionStorage.getItem('profileId') == '6') {
      return true;
    }
    return false;
  }
  public isCsa() {
    if (sessionStorage.getItem('profileId') == '2') {
      return true;
    }
    return false;
  }
  public isAdmin() {
    if (sessionStorage.getItem('profileId') == '1') {
      return true;
    }
    return false;
  }

  set user(user) {
    this._user = user;
  }

  get user() {
    if(!this._user) {
      let data = localStorage.getItem('userData')
      if(data) {
        this.user = JSON.parse(data)
      }
    }
    return this._user;
  }
  set token(token) {
    this._token = token;
  }

  get token() {
    if(!this._token) {
      let data = localStorage.getItem('token')
      if(data) {
        this.token = JSON.parse(data)
      }
    }
    return this._token;
  }

  get decodedToken() {
    let token = this.token;
    let decoded;
    try {
      decoded = JSON.parse(atob(token.split('.')[1]));
    } catch (error) {
      console.log("TCL: AuthenticationService -> get decodedToken -> error", error)
    }
    return decoded;

  }

}
