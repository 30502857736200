import { Component, OnInit, ViewChild, Input, EventEmitter, Output, Pipe } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, PageEvent } from '@angular/material';
import { SelectAutocompleteComponent } from 'mat-select-autocomplete';
import { SelectionModel } from '@angular/cdk/collections';
import { SharedService } from '../../service/shared.service';
import { MatTableExporterDirective, ExportType } from 'mat-table-exporter';

export interface column {
  name: string
  title: string
  type?: string
  classes?: string | Function
  sort?: number
  concatList?: Array<string>
  concat?: {
    with: string
    if?: {
      eq: string
    }
  }
  sticky?: boolean
  sortable?: boolean
  hide?: boolean
  pipe?: {
    name: Pipe,
    args?: Array<string>
  },
  actions?: Array<action>
}

export interface action {
  function: string
  icon?: string
  color?: string
  title?: string
  hide?: boolean
  classes?: Array<string>
}

export interface PagingData {
  pageSizeOptions: Array<Number>;
  length: Number;
  pageSize: Number;
  pageIndex: Number;
  showFirstLastButtons: Boolean;
}

@Component({
  selector: 'app-mat-table',
  templateUrl: './mat-table.component.html',
  styles: [
    `
      :host {
        width: 100%;
      }
    `
  ]
})
export class MTableComponent implements OnInit {
  displayedColumns = [];
  pageSizes = [];
  selectedDate = {};
  selection = new SelectionModel<any>(true, []);
  hasErrorDataInTable: boolean = false;
  _classes: string = '';
  _tableColumns: Array<column> = []
  dataSource: MatTableDataSource<any>
  getValue: any;

  @ViewChild('exporter') exporter: MatTableExporterDirective;
  @ViewChild('paginator', { read: true }) paginator: MatPaginator;
  @ViewChild('daterangepicker') rangePicker: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(SelectAutocompleteComponent) multiSelect: SelectAutocompleteComponent;

  @Output() actionClick = new EventEmitter();
  @Output() scroll = new EventEmitter();
  @Output() onPageChange:EventEmitter<PageEvent> = new EventEmitter();

  @Input() exportConfig = {};
  @Input() pagingData: PagingData = null;
  @Input() id: string = '';
  @Input() isLoading: boolean = false;
  @Input() filterPlaceholder: string = '';
  @Input() isFixedHeight: boolean = false;
  @Input() showFilter: boolean = false;
  @Input() dualPagination: boolean = false;
  @Input()
  set classes(value) {
    this._classes = value;
    if (this.isFixedHeight) {
      this._classes += ' responsive-table table-fixed-height'
    }
  };

  get classes() {
    return this._classes;
  }
  @Input()
  set columns(value) {
    this._tableColumns = value;
    let tableColumns = value.filter((col) => !col.hide)

    this.displayedColumns = tableColumns.sort((a, b) => a['sort'] - b['sort']).map((col) => col.name);

  }
  get columns() {
    return this._tableColumns;
  }

  @Input()
  set data(value) {
    this.dataSource = value;
    this.setMatTableData(value)
  }
  get data() {
    return this.dataSource;
  }

  constructor(
    private shared: SharedService,
  ) { }

  ngOnInit() {
    this.getValue = this.shared.getValue;
  }


  getColumnsToDisplay() {
    let columns = this.displayedColumns;
    return columns;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  setMatTableData(data: any = []) {
    this.dataSource = new MatTableDataSource(data);
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = this.shared.customSortingDataAccessor();

    }, 1);
  }
  /* https://stackblitz.com/angular/ggqreenqvae */
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => {

        this.selection.select(row)
      });
  }

  onScroll() {
    console.log('scroll');
    this.scroll.emit();
  }

  actionClicked(action, col, row) {
    console.log("TCL: MTableComponent -> actionClicked -> action", action)
    let data: any = {};
    data.action = action;
    data.col = col;
    data.row = row;
    this.actionClick.emit(data)
  }

  export() {
    if (this.exporter) {
      this.exporter.exportTable(ExportType.XLSX, this.exportConfig)
    }
  }

  pageChanged(pageEvent: PageEvent) {
    this.onPageChange.emit(pageEvent)
  }

  getClasses(element, col) {
    try {
      let classes = ''
      if (this.getValue(element, col.name) && col.classes) {
        classes = typeof col.classes === 'function' ? col.classes(element) : col.classes;
      }
      return classes;
    } catch (error) {
      console.error("🚀 -> file: mat-table.component.ts -> line 196 -> MTableComponent -> getClasess -> error", error);
      return null
    }
  }
}
