import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dialog-close-btn',
  templateUrl: './dialog-close-btn.component.html',
  styles: [
`
    .close-button{
  float: right;
  top:-24px;
  right:-24px;
}

.close-icon {
  transition: .5s ease-in-out;
}

.close-icon:hover {
  transform: rotate(180deg);
}


`

  ]
})
export class DialogCloseBtnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
