import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'input-error',
  templateUrl: './input-error.component.html',
  styles: []
})
export class InputErrorComponent implements OnInit {


  @Input() formGroup: FormGroup | FormArray;
  @Input() input: FormControl;
  @Input() name: string;
  @Input() ctrlName: string;
  @Input() index: number;

  @Input('input')
  get _input() {
    return this.input;
  }

  set _input(value: FormControl) {
    this.input = value;
    if (this.input && this.input.parent)
      this.formGroup = this.input.parent;
    if (this.index) {
      this.formGroup = this.input.parent.controls[this.index]
    }
  }

  constructor() { }

  ngOnInit() {
  }


  isShowError(): boolean {

    let errorStatus = false;
    if (this.input && this.formGroup) {
      errorStatus = this.formGroup.controls[this.ctrlName].invalid && this.input.touched;
    }
    if(!this.formGroup) {
      errorStatus = this.input.invalid && this.input.touched;
    }
    return errorStatus;
  }


  public get inputCtrl(): FormControl {
    if (this.formGroup)
      return this.formGroup.controls[this.ctrlName]
    else {
      return this.input;
    }
  }

}
