import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalService } from '../../../services/global.service';
import { BsModalService } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';
import { AppComponent } from '../../../app.component';
import { SharedService } from '../../service/shared.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { GeoFire } from 'geofire';

@Component({
  selector: 'reassign-order',
  templateUrl: './reassign-order.component.html',
  styles: [
    `
    :host {
      width: 100%;
      height: 100%;
    }
    `
  ]
})
export class ReassignOrderComponent implements OnInit {

  @Input() order_id: string;

  @Output('reassigned') reassigned = new EventEmitter();
  @Output('noVehiclesAvailable') noVehiclesAvailable = new EventEmitter();

  order: any = {};
  geoFire: any;
  query: any;
  showWhizzers = false;
  statusArray = [];
  reassignmentReasons: any = [];
  allVehicles: any = [];
  availableDrivers: any = [];
  reassignment_reason: string;
  noWhizzers = 'No whizzers available - Whizzers will be displayed once they are online';

  constructor(
    private global: GlobalService,
    private db: AngularFireDatabase,
    private datePipe: DatePipe,
    private shared: SharedService,
    private appComp: AppComponent,
  ) { }

  ngOnInit() {

    this.geoFire = new GeoFire(this.db.database.ref('two_wheelers'));
    this.getOrderDetails();
    this.getAllDrivers();
    this.getReassignmentReasons();
    this.statusArray = [
      this.shared.ORDER_STATUSES.ACCEPTED_BY_DRIVER,
      this.shared.ORDER_STATUSES.ARRIVED_AT_PICKUP,
      this.shared.ORDER_STATUSES.START_TRIP,
      this.shared.ORDER_STATUSES.ARRIVED_AT_DROP,
      this.shared.ORDER_STATUSES.TRIP_ENDED,
      this.shared.ORDER_STATUSES.DRIVER_REMOVED,
    ]
  }

  async getAllDrivers() {
    this.allVehicles = await this.shared.getAllVehiclesFB();
    console.log("TCL: ReassignOrderComponent -> getAllDrivers -> allVehicles", this.allVehicles)
  }

  async getOrderDetails() {
    try {
      let apiInput: any = {};
      apiInput.order_id = this.order_id;
      this.appComp.showLoader();

      let resp: any = await this.global.searchOrders(apiInput).toPromise();
      console.log("TCL: resp", resp);
      if (resp.status) {
        this.order = resp.data.data[0];
      } else {
        this.shared.openSnackBar(resp.error.responseMessage)
      }
    } catch (error) {
      console.log("TCL: error", error)
      this.shared.openSnackBar(error.message);

    } finally {
      this.appComp.hideLoader();

    }
  }

  GetWhizzers(order) {
    this.showWhizzers = true;
    if (order.WhizzersCalled) {
    } else {
      order.WhizzersCalled = true;
      this.availableDrivers = [];
      const lat = +order.pickup_latitude;
      const long = +order.pickup_longitude;
      this.query = this.shared.getGeoFireQuery({ lat, long })
      this.query.on('key_entered', (key, location, distance) => {
        if (this && this.availableDrivers) {
          console.log(key);
          this.availableDrivers.push({
            driver_vehicle_id: this.allVehicles[key]['driver_vehicle_id'],
            driver_name: this.allVehicles[key]['display_name'] || this.allVehicles[key]['driver_name'],
            driver_mobile: this.allVehicles[key]['driver_mobile'],
            distance: distance,
          });
        }
      });

      this.query.on('key_exited', (key, location, distance) => {
        if (this && this.availableDrivers) {
          this.availableDrivers = this.availableDrivers.filter((driver) => driver.driver_vehicle_id != key)
        }
      });

    }
  }

  async setNoWhizzers() {

    const trackData = {
      order_id: this.order_id,
      driver_id: this.order.driver_id,
      order_status: this.shared.ORDER_STATUSES.NO_VEHICLES_AVAILABLE,
    };
    this.db.object('orders/' + this.order_id).update({ order_status: this.shared.ORDER_STATUSES.NO_VEHICLES_AVAILABLE });
    try {
      this.appComp.showLoader();

      let resp: any = await this.global.DriverUpdateOrderStatus(trackData).toPromise();
      console.log("TCL: resp", resp);
      if (resp.status) {
        let data: any = {};
        this.noVehiclesAvailable.emit(data);
      } else {
        this.shared.openSnackBar(resp.error.responseMessage)
      }
    } catch (error) {
      console.log("TCL: error", error)
      this.shared.openSnackBar(error.message);

    } finally {
      this.appComp.hideLoader();

    }
  }

  async reassignment(driver_vehicle_id) {
    try {

      let data: any = {};
      data.text = "Do you want to process <strong>Reassignment</strong>?";
      let confirmation = await this.shared.openDialog(data).toPromise();
      if (!confirmation) {
        return;
      }
      this.appComp.showLoader();

      let body: any = {}
      body.driver_vehicle_id = driver_vehicle_id;
      body.order_id = this.order_id;
      body.csa_id = this.shared.getCsaId();
      body.reassignment_reason = this.reassignment_reason;

      let resp: any = await this.global.reassignment(body).toPromise();
      console.log("TCL: ReassignOrderComponent -> reassignment -> resp", resp)
      if (resp.status) {
        this.shared.openSnackBar(resp.data.Message);
        this.showWhizzers = false;
        let time = this.datePipe.transform(new Date(), 'dd/MM/yyyy hh:mm:ss aa');
        this.db.object('orders/' + this.order_id + '/WOW_api_call').update({ currentDateTime: time });

        let data: any = {}
        this.reassigned.emit(data);
      } else {
        this.shared.openSnackBar(resp.error.responseMessage)
      }
    } catch (error) {
      console.log("TCL: ReassignOrderComponent -> reassignment -> error", error)
      this.shared.openSnackBar(error.message)

    } finally {
      this.appComp.hideLoader();
    }
  }

  async getReassignmentReasons() {
    try {
      let apiInput: any = {};
      apiInput.user_id = this.shared.getUserId();
      this.appComp.showLoader();

      let resp: any = await this.global.getReassingmentReasons(apiInput).toPromise();
      console.log("TCL: resp", resp);
      if (resp.status) {
        this.reassignmentReasons = resp.data.data;
      } else {
        this.shared.openSnackBar(resp.error.responseMessage)
      }
    } catch (error) {
      console.log("TCL: error", error)
      this.shared.openSnackBar(error.message);

    } finally {
      this.appComp.hideLoader();
    }
  }

  ngOnDestroy(): void {
    if (this.query) {
      this.query.cancel();
    }
  }
}
