import * as $ from 'jquery';
// import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutes } from './app.routing';
import { AppComponent, AppNetworkStatusComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';

import { AngularFireModule} from '@angular/fire';
import { AngularFireDatabaseModule} from '@angular/fire/database';
import { environment } from '../environments/environment';
import { DatePipe, CommonModule } from '@angular/common';
import { AppSharedConfirmationComponent } from './shared/shared-confirmation';
import { SelectAutocompleteModule } from 'mat-select-autocomplete';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AngularFireStorageModule, StorageBucket } from '@angular/fire/storage';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AuthenticationService } from './authentication/service/authentication.service';
import { AuthGuardService } from './authentication/service/auth-guard.service';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppBlankComponent,
    AppSidebarComponent,
    AppSharedConfirmationComponent,
    AppNetworkStatusComponent
  ],
  imports: [
    // BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    PerfectScrollbarModule,
    SharedModule,
    RouterModule.forRoot(AppRoutes),
    AngularFireModule.initializeApp(environment.firebase, 'whizzydevelopment'),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    SelectAutocompleteModule,
    CommonModule,
    InfiniteScrollModule,

    NgxMatSelectSearchModule
  ],
  entryComponents: [
    AppSharedConfirmationComponent, AppNetworkStatusComponent
  ],
  providers: [
    AuthGuardService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    AuthenticationService,
    DatePipe,
    { provide: StorageBucket, useValue: 'whizzydevelopment.appspot.com' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
