import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-options',
  templateUrl: './cancel-options.component.html',
})
export class CancelOptionsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CancelOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  setCancelOption(option) {
    this.data['cancelOption'] = option;
  }

  customerCancelTrip() {
    this.data['cancelTrip'] = true;
  }
}
