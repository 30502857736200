import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SharedService } from '../../service/shared.service';

@Component({
  selector: 'date-range',
  templateUrl: './date-range.component.html',
  styles: []
})
export class DateRangeComponent implements OnInit {

  selectedDate: any = {};
  bsValue: Date[];
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() minMode: string = 'day';

  @Output() dateChanged = new EventEmitter()

  constructor(
    public shared: SharedService,
  ) { }

  ngOnInit() {
  }

  onDateRangeValueChange(event) {
    console.log(event);
    this.selectedDate['from'] = this.shared.datePipe.transform(event[0], 'yyyy-MM-dd');
    this.selectedDate['to'] = this.shared.datePipe.transform(event[1], 'yyyy-MM-dd');
    console.log(this.selectedDate)
    this.dateChanged.emit(this.selectedDate)
  }

  reset() {
    this.bsValue = [];
  }
}
