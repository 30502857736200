import { Component, OnInit, Inject } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { interval } from 'rxjs/internal/observable/interval';
import { AuthenticationService } from '../../../authentication/service/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent implements OnInit {
  public userLogged = false;
  public isInternetAvailable = false;
  public dialogStatus = false;
  public config: PerfectScrollbarConfigInterface = {};
  // This is for Notifications
  notifications: Object[] = [
    {
      round: 'round-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      round: 'round-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      round: 'round-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      round: 'round-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    }
  ];

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthenticationService,
  ) { }
  ngOnInit() {
    if (sessionStorage.getItem('userId') || localStorage.getItem('userId')) {
      this.userLogged = true;
    }
  }

  navigateProfile() {
    if (sessionStorage.getItem('profileId') === '3') {
      this.router.navigateByUrl('/merchant');
    } else if (sessionStorage.getItem('profileId') === '2') {
      this.router.navigateByUrl('/user/userProfile');
    } else {
      this.router.navigateByUrl('/user/userProfile');
    }
  }

  logOut() {
    this.userLogged = false;
    sessionStorage.clear();
    localStorage.clear();
    this.auth.user = null
    this.auth.token = null
    this.router.navigateByUrl('/authentication/login');
  }

}
