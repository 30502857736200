import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CancelOptionsComponent } from '../../../scheduled-orders/scheduled-orders/popups/cancel-options/cancel-options.component';
import { SharedService } from '../../service/shared.service';
import { AppComponent } from '../../../app.component';
import { GlobalService } from '../../../services/global.service';
import { MatDialog } from '@angular/material';
import { environment } from '../../../../environments/environment';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'reallot-order',
  templateUrl: './reallot-order.component.html',
  styles: []
})
export class ReallotOrderComponent implements OnInit {

  drivers: any = [];
  selected: any = {};
  scheduledTripCancellationReasonsCustomer: any;
  @Input() order: any;

  @Output() orderCancelled = new EventEmitter();
  @Output() realloted = new EventEmitter();
  
  constructor(
    private appComp: AppComponent,
    private shared: SharedService,
    private dialog: MatDialog,
    private db: AngularFireDatabase,
    private global: GlobalService,
  ) { }

  ngOnInit() {
    this.getAllDrivers();
    this.getScheduledTripCancellationReasonsCustomer();

  }

  getScheduledTripCancellationReasonsCustomer() {
    this.db.object(environment.FB_TABLES.CSA_CANCELLATION_REASONS_TABLE).valueChanges().subscribe(
      data => {
        if (data) {
          this.scheduledTripCancellationReasonsCustomer = data;
        }
      }
    );
  }

  async getAllDrivers() {

    this.appComp.showLoader();
    try {
      let resp: any = await this.shared.getAllDrivers()
      console.log(resp);
      this.drivers = resp;
      this.drivers.forEach(a => {
        a['name_mobile'] = `${a.driver_name} (${a.driver_mobile})`;
      });
    } catch (error) {
      console.log("TCL: getAllDrivers -> error", error)
      this.shared.openSnackBar(error.message)
    } finally {
      this.appComp.hideLoader()
    }
  }

  driverSelectionChanged(e) {
    if (typeof e == 'string') {
      console.log(e);
      let driver = this.drivers.find((d) => d.driver_id == e)
      this.selected.driver = driver;
    }

  }

  reAllot(order) {
    if (!this.selected.driver) {
      this.shared.openSnackBar('Please select the driver first')
      return;
    }

    if (order.driver_mobile == this.selected.driver.driver_mobile) {
      this.shared.openSnackBar('Order is already alloted to this driver')
      return;
    }

    if (!this.selected.driver.Driverdefaultvehicle) {
      this.shared.openSnackBar('Can not allot as this driver does not have a default vehicle')
      return;
    }

    let dialog = this.shared.openDialog();

    dialog.subscribe(result => {
      console.log(result);

      if (result == true) {
        this.doReallot(order);
      }

    });
  }

  async doReallot(order) {
    let {
      order_id,
      driver_id,
    } = order;

    let {
      driver_vehicle_id
    } = this.selected.driver.Driverdefaultvehicle;
    console.log('reAllot');
    this.appComp.showLoader();
    try {
      let removeDriverResp: any = await this.global.RemoveDriverFromOrder({ order_id, driver_id }).toPromise()
      console.log("TCL: doReallot -> removeDriverResp", removeDriverResp)
      if (removeDriverResp.status) {
        try {
          let allotResp: any = await this.global.AllotDriver(order_id, driver_vehicle_id).toPromise()
          console.log("TCL: doReallot -> allotResp", allotResp)
          if (allotResp.status) {
            //  Emit reallotment done
            let data = {};
            this.realloted.emit(data);
          } else {
            this.shared.openSnackBar(allotResp.error.responseMessage)
            //  Emit reallotment failed
          }

        } catch (error) {
          console.log("TCL: doReallot -> error", error)
          this.shared.openSnackBar(error.message)
          //  Emit reallotment failed

        } finally {

        }
      } else {
        this.shared.openSnackBar(removeDriverResp.error.responseMessage)

      }

    } catch (error) {
      console.log("TCL: doReallot -> error", error)
      this.shared.openSnackBar(error.message)
    } finally {
      this.appComp.hideLoader();
    }
  }

  cancel(order) {
    console.log(order);
    let dialogRef;
    dialogRef = this.dialog.open(CancelOptionsComponent, {
      width: '650px',
      data: { cancelOptions: this.scheduledTripCancellationReasonsCustomer }
    });

    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed');
      console.log(result);
      if (result && result['cancelTrip']) {
        const cancelData: any = {
          'cancel_option': result['cancelOption'],
          'user_id': order.user_id,
          'order_id': order.order_id,
          'cancelled_by': 'CSA',
        };

        // if prepayment then ask for refund type bank / credits

        if (order.payment_status == 'Completed') {
          let returnData;
          try {
            returnData = await this.shared.openSelectRefundTypePopup(order);
            console.log("TCL: openModal -> returnData", returnData);
            let body = { ...cancelData, ...returnData }
            this.customerCancelTrip(body);
          } catch (error) {
            console.log("TCL: openModal -> error", error)
          }

        } else {
          this.customerCancelTrip(cancelData);
        }
      }

    });

  }

  async customerCancelTrip(cancelData) {

    try {
      this.appComp.showLoader();
      let response: any = await this.global.CustomerCancelTrip(cancelData).toPromise();
      console.log("TCL: customerCancelTrip -> response", response)
      if (response.status) {
        if (response.data && response.data.refund_resp && response.data.refund_resp.message) {
          this.shared.openSnackBar(response.data.refund_resp.message);
        } else {
          this.shared.openSnackBar(response.data.Message);
        }
        // emit cancel success
        let data = {};
        this.orderCancelled.emit(data);
      } else {
        // emit cancel fail
        this.shared.openSnackBar(response.error.errorMessage);
      }
    } catch (error) {
      // emit cancel fail
      console.error("TCL: customerCancelTrip -> error", error)
      this.shared.openSnackBar(error.message)
    } finally {
      this.appComp.hideLoader();

    }
  }
}
