import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { interval } from 'rxjs/internal/observable/interval';
import { } from 'googlemaps';
declare var google: any;

export interface ConfirmData {
  confirmationTitle: string;
  confirmationText: string;
}

@Component({
  selector: 'app-network-status',
  templateUrl: 'network-status.html',
})
export class AppNetworkStatusComponent {

  constructor(
    public dialogRef: MatDialogRef<AppNetworkStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmData
  ) {
    dialogRef.disableClose = true;
  }

}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  confirmationTitle: string;
  confirmationText: string;

  public browserLocation = false;
  public spinLoader = false;
  public isInternetAvailable = true;
  public dialogStatus = false;
  constructor(
    private router: Router,
    public dialog: MatDialog) { }
  ngOnInit() {

    interval(5000).subscribe(
      (t) => {
        // console.log("Internet is Available", navigator.onLine, this.isInternetAvailable );
        if (!navigator.onLine && !this.dialogStatus) {
          this.isInternetAvailable = false;

          this.confirmationTitle = 'No Internet';
          this.confirmationText = 'Please check your internet connection, \n and please refresh the web page';
          this.openDialog();
        }
        if ( navigator.onLine  && this.dialogStatus && !this.isInternetAvailable ) {
            this.isInternetAvailable = true;
            this.dialogStatus = false;
            this.dialog.closeAll();
            clearInterval();
            this.router.navigateByUrl('/');
        }
        if (navigator.onLine && !this.browserLocation) {
          if ('geolocation' in navigator) {
            // console.log('geolocation in navigator: yes');
            navigator.geolocation.getCurrentPosition(
              (position) => {
                this.browserLocation = true;
                // console.log('geolocation in navigator: yes', position);
              },
              (error) => {
                console.log('Error occurred. Error code: ' + error.code);
                this.confirmationTitle = 'Browser Location';
                this.confirmationText = 'Browser not allowing to access your current location';
                this.openDialog();
              },
              // {maximumAge:Infinity, timeout:0}
              // {
              //   timeout: 10 * 1000
              // }
            );
          } else {
            console.log('geolocation in navigator: no');
            this.confirmationTitle = 'Browser Location';
            this.confirmationText = 'Browser not allowing to access your current location';
            this.openDialog();
          }
        }
      }
    );

  }

  openDialog(): void {
    this.dialogStatus = true;
    this.browserLocation = true;
    const dialogRef = this.dialog.open(AppNetworkStatusComponent, {
      width: '400px',
      data: { confirmationTitle: this.confirmationTitle, confirmationText: this.confirmationText }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // this.dialogStatus = false;
      // this.isInternetAvailable = true;
    });
  }

  showLoader(){
    this.spinLoader = true;
  }

  hideLoader(){
    this.spinLoader = false;
  }
}
