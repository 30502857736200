import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../authentication/service/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  // GetMyCompletedOrders(user_id: string) {
  //   throw new Error("Method not implemented.");
  // }

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    ) { }

  public headerOptionsOLD = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  public headerOptionsNEW = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa('whizzy2:whizzy2@123'),
    'platform': '1',
  });
  public headerOptionsNEWFile = new HttpHeaders({
    // 'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa('whizzy2:whizzy2@123'),
    'platform': '1',
  });

  get headerOptionsNewToken () { 

    let headers = this.headerOptionsNEW;
    headers = headers.append('token', this.auth.token);
    return headers;
  }

  get headerOptionsOldToken () { 

    let headers = this.headerOptionsOLD;
    headers = headers.append('token', this.auth.token);
    return headers;
  }

  dodeleteDedicatedDriver(body: any) {
    body = { ...body, user_id: this.csa_id };
    let url = environment.mysqlAPI + 'merchant/deleteAssociatedDriver';
    return this.http.post(url, body, { headers: this.headerOptionsNEW });
  }
  
  RegisterUserWOW(data: any) {
    return this.http.post(environment.mysqlAPI + 'user/RegisterUserWOW', data, { headers: this.headerOptionsNEW });
  }

  sendOTP(data: any) {
    return this.http.post(environment.mysqlAPI + 'user/SendOTP', data, { headers: this.headerOptionsNEW });
  }

  GetUserDetails(user_mobile) {
    return this.http.get(environment.mysqlAPI + 'user/GetUserDetails?user_mobile=' + user_mobile, { headers: this.headerOptionsNEW });
  }

  UpdateUserPassword(data: any) {
    return this.http.post(environment.serverAPI + 'user/UpdateUserPassword', data, { headers: this.headerOptionsOLD });
  }

  loginUser(data: any) {
    return this.http.post(environment.mysqlAPI + 'user/LoginUser', data, { headers: this.headerOptionsNEW });
  }
/* 
  loginUser(data: any) {
    return this.http.post(environment.serverAPI + 'user/LoginUser', data, { headers: this.headerOptionsOLD });
  }
 */
  CreateUser(data: any) {
    return this.http.post(environment.mysqlAPI + 'user/wowCreateUser', data, { headers: this.headerOptionsNEW });
  }
/* 
  CreateUser(data: any) {
    return this.http.post(environment.serverAPI + 'merchant/CreateUser', data, { headers: this.headerOptionsOLD });
  }
 */
  CreateMerchantCustomer(data: any) {
    return this.http.post(environment.serverAPI + 'customer/CreateMerchantCustomer', data, { headers: this.headerOptionsOldToken });
  }

  EditMerchantCustomer(data: any) {
    return this.http.post(environment.serverAPI + 'customer/EditMerchantCustomer', data, { headers: this.headerOptionsOldToken });
  }

  GetMerchantCustomers(data: any) {
    return this.http.post(environment.serverAPI + 'customer/GetMerchantCustomers', data, { headers: this.headerOptionsOldToken });
  }

  CreateRoster(data: any) {
    return this.http.post(environment.serverAPI + 'roster/CreateRoster', data, { headers: this.headerOptionsOldToken });
  }

  EditRoster(data: any) {
    return this.http.post(environment.serverAPI + 'roster/EditRoster', data, { headers: this.headerOptionsOldToken });
  }

  GetRosterDetails(data: any) {
    return this.http.post(environment.serverAPI + 'roster/GetRosterDetails', data, { headers: this.headerOptionsOldToken });
  }

  GetMerchantRosters(merchantId: any,param = {}) {
    let queryParams = jQuery.param(param);

    return this.http.get(environment.serverAPI + 'roster/GetMerchantRosters/' + merchantId + '?' + queryParams, { headers: this.headerOptionsOldToken });
  }

  AddCustomerToRoster(data: any) {
    return this.http.post(environment.serverAPI + 'customer/AddCustomerToRoster', data, { headers: this.headerOptionsOldToken });
  }

  EditRosterCustomer(data: any) {
    return this.http.post(environment.serverAPI + 'customer/EditRosterCustomer', data, { headers: this.headerOptionsOldToken });
  }

  GetRosterCustomers(data: any) {
    return this.http.post(environment.serverAPI + 'customer/GetRosterCustomers', data, { headers: this.headerOptionsOLD });
  }

  GetAllMerchants(data: any, params = {}) {
    let query = this.generateQueryString(params);

    return this.http.post(environment.serverAPI + 'merchant/GetAllMerchants/' + query, data, { headers: this.headerOptionsOLD });
  }

  GetMerchantDetails(merchantId: any) {
    return this.http.get(environment.serverAPI + 'merchant/GetMerchantDetails/' + merchantId, { headers: this.headerOptionsOLD });
  }

  UpdateMerchantDetails(data: any) {
    return this.http.post(environment.serverAPI + 'merchant/UpdateMerchant/', data, { headers: this.headerOptionsOLD });
  }

  GetTodayRosterCustomers(data: any) {
    return this.http.post(environment.serverAPI + 'merchant/GetTodayRosterCustomers/', data, { headers: this.headerOptionsOldToken });
  }

  MerchantApproveRosters(data: any) {
    return this.http.post(environment.serverAPI + 'merchant/MerchantApproveRosters/', data, { headers: this.headerOptionsOldToken });
  }

  GetApprovedRosters(data: any) {
    return this.http.post(environment.serverAPI + 'roster/GetApprovedRosters/', data, { headers: this.headerOptionsOLD });
  }

  MerchantRemoveRoster(data: any) {
    return this.http.post(environment.serverAPI + 'merchant/MerchantRemoveRoster/', data, { headers: this.headerOptionsOldToken });
  }

  CreateDailyRosters(data: any) {
    return this.http.post(environment.serverAPI + 'merchant/CreateDailyRosters/', data, { headers: this.headerOptionsOldToken });
  }

  UpdateOrderToRosters(data: any) {
    return this.http.post(environment.serverAPI + 'roster/UpdateOrderToRosters/', data, { headers: this.headerOptionsOLD });
  }

  GetWowRosterSummary(currentDate) {
    let merchant_id = sessionStorage.getItem('merchantId');
    if(sessionStorage.getItem('profileId') == '5'){
      merchant_id = sessionStorage.getItem('parentId')
    }
    let param = `?merchant_id=${merchant_id}&date=${currentDate}`;
    // let param = `?merchant_id=77&date=2019-10-23`;
    return this.http.get(environment.mysqlAPI + 'merchant/getWowRosterSummary' + param, { headers: this.headerOptionsNewToken });
  }

  // ###################

  CreateOrder_V9(formData) {
    return this.http.post(environment.mysqlAPI + 'order/CreateOrder_V13', formData, { headers: this.headerOptionsNewToken });
  }

  ValidateCoupon(formData) {
    const data = 'user_id=' + formData.user_id + '&coupon_code=' + formData.coupon_code + '&fare=' + formData.fare;
    return this.http.get(environment.mysqlAPI + 'user/ValidateCoupon?' + data, { headers: this.headerOptionsNEW });
  }

  GetWhizzyVehicles(userId, from_latitude, from_longitude, to_latitude, to_longitude) {
    const trip_type = 'one_way';
    const data = 'user_id=' + userId
      + '&from_latitude=' + from_latitude
      + '&from_longitude=' + from_longitude
      + '&to_latitude=' + to_latitude
      + '&to_longitude=' + to_longitude
      + '&trip_type=' + trip_type;
    return this.http.get(environment.mysqlAPI + 'vehicle/GetWhizzyVehicles?' + data, { headers: this.headerOptionsNewToken });
  }

  GetWhizzyVehicles2(data) {
    var params = ''
    Object.keys(data.params).forEach((key) => {
      params += `${key}=${data.params[key]}&`
    })

    return this.http.post(environment.mysqlAPI + 'vehicle/GetWhizzyVehicles2?' + params ,data.body, { headers: this.headerOptionsNewToken });
  }


  /* Service added by Suhail Akhtar */
  
  GetDriverDetails(order_id) {
    return this.http.get(environment.mysqlAPI + 'driver/GetDriverDetails?order_id=' + order_id, { headers: this.headerOptionsNEW });
  }

  SendTrackingLink(trackData) {
    return this.http.post(environment.mysqlAPI + 'order/SendTrackingLink', trackData, { headers: this.headerOptionsNEW });
  }

  getPaymentDetails(paymentRequestData) {
    return this.http.post(environment.mysqlAPI + 'order/razorpay', paymentRequestData, { headers: this.headerOptionsNEW });
  }

  UpdateOrderPaymentStatus(paymentData) {
    return this.http.post(environment.mysqlAPI + 'order/UpdateOrderPaymentStatus', paymentData, { headers: this.headerOptionsNEW });
  }

  // GetTripSummary(orderId) {
  //   return this.http.get(environment.cassandraAPI + 'GetTripSummary?order_id=' + orderId, { headers: this.headerOptionsOLD });
  // }

  GetMyOrders(user_id, page = 1) {
    let url = environment.mysqlAPI + 'order/GetMyOrders?user_id=' + user_id;
    url += `&page_no=${page}`;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }

  GetMyOngoingOrders(user_id, page = 1) {
    let url = environment.mysqlAPI + 'order/GetOngoingOrders?user_id=' + user_id;
    url += `&page_no=${page}`;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }

  GetMerchantOngoingOrders(merchant_id, user_id,page=1) {
    let path = 'order/GetMerchantOngoingOrders?merchant_id=' + merchant_id + '&user_id=' + user_id;
    path += `&page_no=${page}`;
    return this.http.get(environment.mysqlAPI + path, { headers: this.headerOptionsNewToken });
  }

  GetMerchantCompletedOrders(merchant_id, user_id, page = 1) {
    let path = 'order/GetMerchantCompletedOrders?merchant_id=' + merchant_id + '&user_id=' + user_id;
    path += `&page_no=${page}`;
    return this.http.get(environment.mysqlAPI + path, { headers: this.headerOptionsNewToken });
  }

  GetMyScheduledOrders(user_id, page = 1) {
    let url = environment.mysqlAPI + 'order/GetMyScheduledOrders?user_id=' + user_id;
    url += `&page_no=${page}`;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }

  GetAllScheduledOrders(user_id) {
    user_id = '';
    return this.http.get(environment.mysqlAPI + 'order/GetAllScheduledOrders', { headers: this.headerOptionsNEW });
  }

  GetDropContacts(order_id) {
    return this.http.get(environment.mysqlAPI + 'order/ScheduledOrders/DropContacts/' + order_id, { headers: this.headerOptionsNEW });
  }

  CustomerCancelTrip(cancelData) {
    cancelData = { csa_id: this.csa_id, ...cancelData };
    return this.http.post(environment.mysqlAPI + 'order/CustomerCancelTripV1', cancelData, { headers: this.headerOptionsNEW });
  }
  
  EditScheduledOrder(scheduledData) {
    scheduledData = { ...scheduledData, csa_id: this.csa_id };
    return this.http.post(environment.mysqlAPI + 'order/EditScheduledOrder', scheduledData, { headers: this.headerOptionsNEW });
  }

  GetOrderUserDetails(orderId) {
    const path = 'order/GetOrderUserDetails?order_id=' + orderId;
    return this.http.get(environment.mysqlAPI + path, { headers: this.headerOptionsNEW });
  }

  ManuallyAllocateDriver(orderId, driver_vehicle_id) {
    let path = 'order/ManuallyAllocateDriver?order_id=' + orderId + '&driver_vehicle_id=' + driver_vehicle_id;
    path += `&csa_id=${this.csa_id}`;
    return this.http.get(environment.mysqlAPI + path, { headers: this.headerOptionsNEW });
  }

  GetDriverOngoingOrders(trackData) {
    const path = 'driver/GetDriverOngoingOrders';
    return this.http.post(environment.mysqlAPI + path, trackData, { headers: this.headerOptionsNEW });
  }

  GetDriverAllotedOrders(trackData) {
    const path = 'order/GetDriverAllotedOrders?driver_id=0&driver_mobile='+trackData;
    return this.http.get(environment.mysqlAPI + path, { headers: this.headerOptionsNEW });
  }

  RemoveDriverFromOrder(trackData) {
    const path = 'order/RemoveDriverFromOrder';
    trackData = { ...trackData, csa_id: this.csa_id };
    return this.http.post(environment.mysqlAPI + path, trackData, { headers: this.headerOptionsNEW });
  }

  DriverUpdateOrderStatus(trackData) {
    const path = 'order/DriverUpdateOrderStatus';
    trackData = { ...trackData, csa_id: this.csa_id };
    return this.http.post(environment.mysqlAPI + path, trackData, { headers: this.headerOptionsNEW });
  }

  getAllDrivers(where = {}) {
    let path = 'driver/GetAllDrivers'
    return this.http.get(environment.mysqlAPI + path, { headers: this.headerOptionsNEW });
  } 

  getAllDriversOptim(where) {
    const path = 'driver/GetAllDriversOptim';
    return this.http.post(environment.mysqlAPI + path,where, { headers: this.headerOptionsNEW });
  } 

  AllotDriver(order_id,driver_vehicle_id) {
    let path = 'order/AllotDriver';
    path += `?order_id=${order_id}&driver_vehicle_id=${driver_vehicle_id}`;
    path += `&csa_id=${this.csa_id}`;
    return this.http.get(environment.mysqlAPI + path, { headers: this.headerOptionsNEW });
  }

  addCSAToOrder(order_id){
    let csa_id = sessionStorage.getItem('userId');
    let param = `?order_id=${order_id}&csa_id=${csa_id}`;
    return this.http.get(environment.mysqlAPI + 'order/UpdateOrderAddCSA' + param, { headers: this.headerOptionsNEW });
  }
  GetZones() {
    let merchant_id = sessionStorage.getItem('merchantId');
    let param = `?merchant_id=${merchant_id}`;
    return this.http.get(environment.mysqlAPI + 'business/GetZones' + param, { headers: this.headerOptionsNewToken });
  }
  GetMenuItems() {
    let merchant_id = sessionStorage.getItem('merchantId');
    let param = `?merchant_id=${merchant_id}`;
    return this.http.get(environment.mysqlAPI + 'business/GetMenuItems' + param, { headers: this.headerOptionsNEW });
  }
  GetMerchantMenuItems(merchant_id) {
    let param = `?merchant_id=${merchant_id}`;
    return this.http.get(environment.mysqlAPI + 'business/GetMenuItems' + param, { headers: this.headerOptionsNEW });
  }

  ReplaceItemInDailyRosters(body) {
    const path = 'merchant/ReplaceItemInDailyRosters';
    return this.http.post(environment.serverAPI + path, body, { headers: this.headerOptionsOldToken });
  }

  UpdateDailyRoster(body) {
    const path = 'merchant/UpdateDailyRoster';
    return this.http.post(environment.serverAPI + path, body, { headers: this.headerOptionsOldToken });
  }

  AddCustomerToTodayRoster(body) {
    const path = 'merchant/AddCustomerToTodayRoster';
    return this.http.post(environment.serverAPI + path, body, { headers: this.headerOptionsOldToken });
  }

  // Whizzer portal APIs

  addDriver(data: any) {
    return this.http.post(environment.mysqlAPI + 'driver/addDriver', data, { headers: this.headerOptionsNEW });
  }

  updateDriver(data: any) {
    return this.http.post(environment.mysqlAPI + 'driver/updateDriver', data, { headers: this.headerOptionsNEW });
  }

  DriverChangeDefaultVehicle(data: any) {
    return this.http.post(environment.mysqlAPI + 'driver/DriverChangeDefaultVehicle', data, { headers: this.headerOptionsNEW });
  }

  addVehicle(data: any) {
    return this.http.post(environment.mysqlAPI + 'vehicle/addVehicle', data, { headers: this.headerOptionsNEW });
  }

  updateVehicle(data: any) {
    return this.http.post(environment.mysqlAPI + 'vehicle/updateVehicle', data, { headers: this.headerOptionsNEW });
  }
  
  GetAllWhizzers(params: any = {}) {
    params = { ...params, limit: 999999 };
    let query = this.generateQueryString(params);
    let url = environment.mysqlAPI + 'driver/GetAllWhizzers';
    url = `${url}${query}`;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }
  
  GetAllWhizzers2(params: any = {}) {
    params = { ...params, limit: 999999 };
    let query = this.generateQueryString(params);
    let url = environment.mysqlAPI + 'driver/GetAllWhizzers2';
    url = `${url}${query}`;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }
  GetAllNewWhizzers(params: any = {}) {
    params = { ...params, limit: 999999 };
    let query = this.generateQueryString(params);
    let url = environment.mysqlAPI + 'driver/GetAllNewWhizzers';
    url = `${url}${query}`;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  settleCIH(data: any) {
    let url = `${environment.mysqlAPI}driver/settleCIH`;
    return this.http.post(url, data, { headers: this.headerOptionsNEW });
  }

  GetCIH(params) {
    let {
      driver_id,
    } = params;
    let url = `${environment.mysqlAPI}driver/GetCIH/`;
    url += `${driver_id}`;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  getFuelReport(params) {
    let url = `${environment.mysqlAPI}driver/getFuelReport`;
    if (params && params.start) {
      url += `/${params.start}`;
    }
    if (params && params.end) {
      url += `/${params.end}`;
    }
    if (params && params.merchant_id) {
      url += `?merchant_id=${params.merchant_id}`
    }
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  private generateQueryString(object) {
    let query = '?';
    if (typeof object != 'object') return query;
    Object.keys(object).forEach((key) => {
      query += `${key}=${object[key]}&`
    });
    return query;
  }
  
  addMerchantRateCard(data: any) {
    let url = `${environment.mysqlAPI}merchant/addMerchantRateCard`;
    return this.http.post(url, data, { headers: this.headerOptionsNEW });
  }
  
  GetAllMerchantTypes() {
    let url = `${environment.mysqlAPI}merchant/GetTypes`;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }
  GetAllMerchantSubTypes() {
    let url = `${environment.mysqlAPI}merchant/GetSubTypes`;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }
  
  updateMerchant(data:any) {
    let url = `${environment.mysqlAPI}merchant/update`;
    return this.http.post(url, data, { headers: this.headerOptionsNEW });
  }

  addMenuForMerchant(data: any) {
    let url = `${environment.mysqlAPI}merchant/addMenuForMerchant`;
    return this.http.post(url, data, { headers: this.headerOptionsNEW });
  }

  
  GetMerchantRateCard(merchant_id) {
    let url = `${environment.mysqlAPI}merchant/getRateCard/${merchant_id}`;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  getOnlineLogsV2(params) {
    let url = `${environment.mysqlAPI}driver/online_logs`;
    let query = this.generateQueryString(params);
    url = `${url}${query}`;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  getOnlineLogs(params) {
    let url = `${environment.mysqlAPI}driver/getOnlineLogs`;
    let query = this.generateQueryString(params);
    url = `${url}${query}`;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  getOnlineLogSummary(params) {
    let {
      driver_id,
      from,
      to,
    } = params;
    let url = `${environment.mysqlAPI}driver/getOnlineLogSummary/${driver_id}?from=${from}&to=${to}`;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  get csa_id() {
    return sessionStorage.getItem('userId');
  }


  dailyRosterSheetUpload(data: any) {
    let url = `${environment.mysqlAPI}merchant/dailyRosterSheetUpload`;
    return this.http.post(url, data, { headers: this.headerOptionsNEWFile });
  }

  updateRefundStatus(data: any) {
    let url = `${environment.mysqlAPI}order/updateRefundStatus`;
    data = {...data, user_id: this.csa_id};
    return this.http.post(url, data, { headers: this.headerOptionsNEW });
  }

  getMerchantCancelledOrdersForRefund(params) {
    let {
      from,
      to,
    } = params;
    let url = `${environment.mysqlAPI}order/getMerchantCancelledOrdersForRefund?from=${from}&to=${to}`;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  GetAllCancelledOrders(user_id, page = 1) {
    let url = environment.mysqlAPI + 'order/GetAllCancelledOrders?user_id=' + user_id;
    url += `&page_no=${page}`;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  getAdminTransactions(params) {
    let param = this.generateQueryString(params);;
    return this.http.get(environment.mysqlAPI + 'admin/getTransactions' + param, { headers: this.headerOptionsNEW });
  }


  addTransaction(data: any) {
    let url = `${environment.mysqlAPI}admin/addTransaction`;
    return this.http.post(url, data, { headers: this.headerOptionsNEW });
  }

  getFoodTypes() {
    let params = `?status=active`;
    return this.http.get(environment.mysqlAPI + 'merchant/getFoodTypes' + params, { headers: this.headerOptionsNEW });
  }


  getCuisineTypes() {
    let params = `?status=active`;
    return this.http.get(environment.mysqlAPI + 'merchant/getCuisineTypes' + params, { headers: this.headerOptionsNEW });
  }

  getMerchantCuisineTypes(merchant_id:any) {
    let params = `?merchant_id=${merchant_id}`;
    return this.http.get(environment.mysqlAPI + 'merchant/getMyCuisineTypes' + params, { headers: this.headerOptionsNewToken });
  }

  searchOrders(query) {
   
    let q2 = this.generateQueryString(query);
    return this.http.get(environment.mysqlAPI + 'order/searchV1' + q2, { headers: this.headerOptionsNEW });
  }

  addAssociateWhizzerWithMerchant(data) {
    let url = environment.mysqlAPI + 'merchant/addAssociateWhizzerWithMerchant';
    return this.http.post(url, data, { headers: this.headerOptionsNEW });
  }

  getAssociatedDrivers(params: any = {}) {
    let query = this.generateQueryString(params);

    return this.http.get(environment.mysqlAPI + 'merchant/getAssociatedDrivers' + query, { headers: this.headerOptionsNEW });
  }

  updateAssociatedDriver(id, data = {}) {
    let url = `${environment.mysqlAPI}merchant/updateAssociatedDriver/${id}`;
    return this.http.patch(url, data, { headers: this.headerOptionsNewToken });
  }

  getMerchantInvoice(params: any = {}) {
    let query = this.generateQueryString(params);
    return this.http.get(environment.mysqlAPI + 'order/merchantInvoiceV2' + query, { headers: this.headerOptionsNewToken });
  }

  getMerchantOrders(params: any = {}) {
    let query = this.generateQueryString(params);
    return this.http.get(environment.mysqlAPI + 'order/merchantOrders' + query, { headers: this.headerOptionsNewToken });
  }

  generateAndGetMerchantMonthlyInvoices(params: any = {}) {
    let query = this.generateQueryString(params);
    return this.http.get(environment.mysqlAPI + 'order/generateAndGetMerchantMonthlyInvoices' + query, { headers: this.headerOptionsNewToken });
  }

  getMerchantMonthlyInvoices(params: any = {}) {
    let query = this.generateQueryString(params);
    return this.http.get(environment.mysqlAPI + 'order/getMerchantMonthlyInvoices' + query, { headers: this.headerOptionsNewToken });
  }

  getAttendance(params: any = {}) {
    let query = this.generateQueryString(params);

    return this.http.get(environment.mysqlAPI + 'driver/getAttendance' + query, { headers: this.headerOptionsNEW });
  }

  addAttendance(body: any = {}) {
    return this.http.post(environment.mysqlAPI + 'driver/addAttendance', body, { headers: this.headerOptionsNEW });
  }
  
  addDriverAttendanceExtraInfo(body: any = {}) {
    return this.http.post(environment.mysqlAPI + 'driver/addDriverAttendanceExtraInfo', body, { headers: this.headerOptionsNEW });
  }

  getHomechefSettlements(params: any = {}) {
    let query = this.generateQueryString(params);

    return this.http.get(environment.mysqlAPI + 'merchant/getHomechefSettlements' + query, { headers: this.headerOptionsNEW });
  } 

  doHomechefSettlement(body: any = {}) {
    return this.http.post(environment.mysqlAPI + 'merchant/doHomechefSettlement', body, { headers: this.headerOptionsNEW });
  } 
  
  getAllotedOrders(params: any = {}) {
    let query = this.generateQueryString(params);

    return this.http.get(environment.mysqlAPI + 'order/alloted' + query, { headers: this.headerOptionsNEW });
  } 

  getDriverEmploymentTypes(){
    return this.http.get(environment.mysqlAPI + 'driver/getDriverEmploymentTypes', { headers: this.headerOptionsNEW });
  }

  
  getRegularCancelledOrdersForRefund(params) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}order/getRegularRefundOrders` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  updateRegularRefundStatus(data: any) {
    let url = `${environment.mysqlAPI}order/updateRegularRefundStatus`;
    data = {...data, user_id: this.csa_id};
    return this.http.post(url, data, { headers: this.headerOptionsNEW });
  }
  addRefundEntry(data: any) {
    let url = `${environment.mysqlAPI}order/addRefundEntry`;
    return this.http.post(url, data, { headers: this.headerOptionsNEW });
  }

  getAssigned(params = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}order/assigned` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  reassignment(data: any = {}) {
    let url = `${environment.mysqlAPI}order/reassignment`;
    return this.http.post(url, data, { headers: this.headerOptionsNEW });
  }

  addBranch(data: any = {}) {
    let url = `${environment.mysqlAPI}merchant/addBranch`;
    return this.http.post(url, data, { headers: this.headerOptionsNewToken });
  }

  getBranches(params = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/branches` + query;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }

  addBillToZoho(data: any = {}) {
    let url = `${environment.mysqlAPI}merchant/addBillToZoho`;
    return this.http.post(url, data, { headers: this.headerOptionsNEW });
  }

  
  cihSettlementHistory(params = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}driver/cihSettlementHistory` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  deleteBranch(data: any = {}) {
    let url = `${environment.mysqlAPI}merchant/deleteBranch`;
    return this.http.post(url, data, { headers: this.headerOptionsNewToken });
  }
 
  getAllOrders(params = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}order/getAllOrders` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }
  getOrdersForView(params = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}order/getOrdersForView` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  getReassingmentReasons(params = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}order/getReassingmentReasons` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  addOrderComment(data: any = {}) {
    let url = `${environment.mysqlAPI}order/addOrderComment`;
    return this.http.post(url, data, { headers: this.headerOptionsNEW });
  }

  
  getOrderStatuses(params = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}order/getOrderStatuses` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  
  createDetoxOrder(data: any = {}) {
    let url = `${environment.mysqlAPI}order/createDetoxOrder`;
    return this.http.post(url, data, { headers: this.headerOptionsNEW });
  }

  getSettlementCSAComments(params: any = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/getSettlementCSAComments` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  
  addSettlementCSAComment(data: any = {}) {
    let url = `${environment.mysqlAPI}merchant/addSettlementCSAComment`;
    return this.http.post(url, data, { headers: this.headerOptionsNEW });
  }

  getMenuCategories(params: any = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/getMenuCategoriesV2` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  addMenuItem(data: any = {}) {
    let url = `${environment.mysqlAPI}merchant/addMenuItemV1`;
    return this.http.post(url, data, { headers: this.headerOptionsNEW });
  }

  updateMenuItem(data: any = {}) {
    let url = `${environment.mysqlAPI}merchant/updateMenuItem`;
    return this.http.post(url, data, { headers: this.headerOptionsNEW });
  }


  getDefaultMenuCategories(params: any = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/getDefaultMenuCategories` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }


  GetMenuCategories(params: any = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/GetMenuCategories` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  getMenuCategoriesAndItems(params: any = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/getMenuCategoriesAndItems` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  addMenuCategory(data: any = {}) {
    let url = `${environment.mysqlAPI}merchant/addMenuCategory`;
    return this.http.post(url, data, { headers: this.headerOptionsNEW });
  }
  
  getMenuCategoriesCount(params: any = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/getMenuCategoriesCount` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  } 

  getMenuItemsV2(params: any = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/getMenuItemsV2` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  getMerchantCategories(params: any = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/getMerchantCategories` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  getItemImageCatalogCategories(params: any = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/getItemImageCatalogCategories` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  searchItemImages(cat_id, params: any = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/searchItemImages/${cat_id}` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  getTaxSlabs(params: any = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/getItemTaxSlabs` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }

  menuItemSheetUpload(data: any = {}) {
    let url = `${environment.mysqlAPI}merchant/menuItemSheetUpload`;
    return this.http.post(url, data, { headers: this.headerOptionsNEWFile });
  }

  getItemRejectReasons(params: any = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/getItemRejectReasons` + query;
    return this.http.get(url, { headers: this.headerOptionsNEW });
  }
  
  getMerchantUsers(params: any = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/users` + query;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }

  
  GetModules(data: any = {}) {

    let url = `${environment.mysqlAPI}user/GetModules`;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }

  updateUserPermissions(data: any = {}) {

    let url = `${environment.mysqlAPI}merchant/updateUserPermissions`;
    return this.http.post(url, data, { headers: this.headerOptionsNewToken });
  }
  
  updateMerchantUser(data: any = {}) {
    let url = `${environment.mysqlAPI}merchant/updateMerchantUser`;
    return this.http.post(url, data, { headers: this.headerOptionsNewToken });
  }

   
  getUserPermissions(data: any = {}) {

    let url = `${environment.mysqlAPI}user/getUserPermissions`;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }
   
  getMerchantMenuSampleFileLinks(data: any = {}) {
    let query = this.generateQueryString(data);

    let url = `${environment.mysqlAPI}merchant/getMerchantMenuSampleFileLinks${query}`;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }
 
  updatePassword(data: any = {}) {
    let url = `${environment.mysqlAPI}merchant/updatePassword`;
    return this.http.post(url, data, { headers: this.headerOptionsNewToken });
  }

  getDropPaymentOrders(params = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}order/getDropPaymentOrders` + query;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }

  getMerchantItemVariantMeasures(params = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/getMerchantItemVariantMeasures` + query;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }

  getWCPromotions(params = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}wc/promotions` + query;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }

  giveCreditsToUser(data) {
    let url = environment.mysqlAPI + 'wc/giveCreditsToUser';
    return this.http.post(url, data, { headers: this.headerOptionsNewToken });
  }

  addDropToScheduledOrder(data) {
    let url = environment.mysqlAPI + 'order/addDropToScheduledOrder';
    return this.http.post(url, data, { headers: this.headerOptionsNewToken });
  }

  updateInvoicePaymentStatus(data) {
    let url = environment.mysqlAPI + 'merchant/updateInvoicePaymentStatus';
    return this.http.post(url, data, { headers: this.headerOptionsNewToken });
  }

  createInvoicePaymentOrder(data) {
    let url = environment.mysqlAPI + 'merchant/createInvoicePaymentOrder';
    return this.http.post(url, data, { headers: this.headerOptionsNewToken });
  }

  razorpayPaymentCallback(data) {
    let url = environment.mysqlAPI + 'order/razorpayPaymentCallback';
    return this.http.post(url, data, { headers: this.headerOptionsNewToken });
  }

  getDashboardData(params = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/getDashboardData` + query;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }

  getMerchantInvoiceRatecard(params = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/getMerchantInvoiceRatecard` + query;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }

  getCSADashboardData(params = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}order/getCSADashboardData` + query;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }

  addMerchantInvoiceRatecard(data) {
    let url = environment.mysqlAPI + 'merchant/addMerchantInvoiceRatecard';
    return this.http.post(url, data, { headers: this.headerOptionsNewToken });
  }

  getMerchantMonthlyInvoicesList(params = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/getMerchantMonthlyInvoicesList` + query;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }

  GetWhizzyVehicles_V2(data) {
    var params = ''
    Object.keys(data.params).forEach((key) => {
      params += `${key}=${data.params[key]}&`
    })

    return this.http.post(environment.mysqlAPI + 'vehicle/GetWhizzyVehicles_V2?' + params ,data.body, { headers: this.headerOptionsNewToken });
  }

  getClubMembers(data) {
    var params = ''
    Object.keys(data).forEach((key) => {
      params += `${key}=${data[key]}&`
    })

    return this.http.get(environment.mysqlAPI + 'merchant/getClubMembers?' + params , { headers: this.headerOptionsNewToken });
  }

  verifyUserClubVerification(data) {
    let url = environment.mysqlAPI + 'merchant/verifyUserClubVerification';
    return this.http.post(url, data, { headers: this.headerOptionsNewToken });
  }
  
  declineUserClubVerification(data) {
    let url = environment.mysqlAPI + 'merchant/declineUserClubVerification';
    return this.http.post(url, data, { headers: this.headerOptionsNewToken });
  }

  getCities(params = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/cities` + query;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }

  getCitiesDrivers(params = {}) {
    const query = this.generateQueryString(params);

    const url = `${environment.mysqlAPI}driver/cities` + query;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }

  getDriverRegRequests(params = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}driver/regRequest` + query;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }

  updateDriverRegRequest(data = {}) {
    let url = `${environment.mysqlAPI}driver/regRequest`;
    return this.http.put(url, data, { headers: this.headerOptionsNewToken });
  }

  getShiftTimings(merchant_id, params = {}) {
    let query = this.generateQueryString(params);

    let url = `${environment.mysqlAPI}merchant/shift_timings/` + merchant_id + query;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }
  
  addShiftTimings(data) {
    let url = environment.mysqlAPI + 'merchant/shift_timings';
    return this.http.post(url, data, { headers: this.headerOptionsNewToken });
  }
  
  doDeleteShift({ shift_id, merchant_id }) {
    let url = `${environment.mysqlAPI}merchant/shift_timings/${merchant_id}/${shift_id}`
    return this.http.delete(url, { headers: this.headerOptionsNewToken });
  }
  getDesignationList() {
    let url =
      environment.backednAPI +
      "admin/ranks?userid=09753639-8f96-4031-b727-c6ab913032fb";
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }
  addBenefits(body) {
    let url =
      environment.backednAPI +
      "admin/benefits?userid=09753639-8f96-4031-b727-c6ab913032fb";
    return this.http.post(url, body, { headers: this.headerOptionsNewToken });
  }
  getBenefits() {
    let url =
      environment.backednAPI +
      "admin/benefits?userid=09753639-8f96-4031-b727-c6ab913032fb";
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }

  getLevels() {
    let url =
      environment.backednAPI +
      "levels?userid=09753639-8f96-4031-b727-c6ab913032fb";
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }
  getOrderLog() {
    let url =
      environment.backednAPI +
      "admin/orders?userid=09753639-8f96-4031-b727-c6ab913032fb";
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }
  deleteBenefit(benefitId) {
    let url = `${environment.backednAPI}admin/benefits/${benefitId}?userid=09753639-8f96-4031-b727-c6ab913032fb`;
    return this.http.delete(url, { headers: this.headerOptionsNewToken });
  }
  getBenefitsByRanks() {
    let url = `${environment.backednAPI}admin/benefits/ranks?userid=09753639-8f96-4031-b727-c6ab913032fb`;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }
  getRanks() {
    let url = `${environment.backednAPI}admin/ranks?userid=09753639-8f96-4031-b727-c6ab913032fb`;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }
  editBenefit(body, id) {
    let url = `${environment.backednAPI}admin/benefits/${id}?userid=09753639-8f96-4031-b727-c6ab913032fb`;
    return this.http.put(url, body, { headers: this.headerOptionsNewToken });
  }
  addBenefitToRank(body) {
    let url = `${environment.backednAPI}admin/benefits/assign?userid=09753639-8f96-4031-b727-c6ab913032fb`;
    return this.http.post(url, body, { headers: this.headerOptionsNewToken });
  }
  getUsers() {
    let url = `${environment.backednAPI}admin/users?userid=09753639-8f96-4031-b727-c6ab913032fb`;
    return this.http.get(url, { headers: this.headerOptionsNewToken });
  }
  addMessage(body) {
    let url = `${environment.backednAPI}admin/messages?userid=09753639-8f96-4031-b727-c6ab913032fb`;
    return this.http.post(url, body, { headers: this.headerOptionsNewToken });
  }
  updateOrderStatus(id, body) {
    let url = `${environment.backednAPI}admin/orders/${id}?userid=09753639-8f96-4031-b727-c6ab913032fb`;
    return this.http.put(url, body, { headers: this.headerOptionsNewToken });
  }
  uploadOrderData(body) {
    const url = `${environment.backednAPI}admin/uploads/orders`;
    return this.http.post(url, body, { headers: this.headerOptionsNewToken });
  }
  addNotification(body) {
    const url = `${environment.backednAPI}pushnotifications`;
    return this.http.post(url, body, { headers: this.headerOptionsNewToken });
  }
  getNotifications() {
    const url = `${environment.backednAPI}pushnotifications`;
    return this.http.get(url,{ headers: this.headerOptionsNewToken });
  }
  getDriverReferrals() {
    const url = `${environment.backednAPI}admin/drivers/referrals`;
    return this.http.get(url,{ headers: this.headerOptionsNewToken });
  }
}
