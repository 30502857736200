import { Directive, ViewChild, ElementRef, Renderer2, HostBinding } from '@angular/core';

@Directive({
  selector: '[topScroll]'
})
export class TopScrollDirective {

  @ViewChild('topScrollBar') topScrollBar: ElementRef;
  @HostBinding('style.height.px') height: string;
  @HostBinding('style.width.px') width: string;

  topScrollbarWidth: string;
  private topScrollEl: any;
  private observer: any;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,

  ) {
    this.calculate()

  }



  private calculate(): void {

    // create new element
    let el = this.renderer.createElement('div');
    let div = this.renderer.createElement('div');
    el.classList.add('overflow');
    el.appendChild(div);
    this.topScrollEl = el;
    this.el.nativeElement.insertAdjacentElement('beforebegin', el);

    setTimeout(() => {
      this.topScrollEl.style.width = this.el.nativeElement.scrollWidth + 'px';
      this.setWidthHeight2()
      this.setWidthHeight1()
    }, 0);

    this.el.nativeElement.addEventListener('scroll', () => {
      if (this.showScrollBar()) {
        this.setWidthHeight1()
      }
    });


    this.topScrollEl.addEventListener('scroll', () => {
      if (this.showScrollBar()) {
        this.setWidthHeight2()
      }
    });
  }
  setWidthHeight2() {
    let scrollBarHt = this.el.nativeElement.offsetHeight - this.el.nativeElement.clientHeight;
    this.topScrollEl.firstChild.style.height = scrollBarHt + 'px';
    this.topScrollEl.style.height = scrollBarHt + 'px';
    this.topScrollEl.firstChild.style.width = this.el.nativeElement.scrollWidth + 'px';
    this.el.nativeElement.scrollLeft = this.topScrollEl.scrollLeft;
  }
  setWidthHeight1() {
    let scrollBarHt = this.el.nativeElement.offsetHeight - this.el.nativeElement.clientHeight;
    this.topScrollEl.style.height = scrollBarHt + 'px';
    this.topScrollEl.firstChild.style.height = scrollBarHt + 'px';
    this.topScrollEl.firstChild.style.width = this.el.nativeElement.scrollWidth + 'px';
    this.topScrollEl.scrollLeft = this.el.nativeElement.scrollLeft;
  }
  private showScrollBar(): boolean {
    return this.el.nativeElement.scrollWidth > this.el.nativeElement.offsetWidth;
  }


  ngAfterViewInit() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(function (mutation) {
        console.log(mutation.type);
      });
    });
    var config = { attributes: true, childList: true, characterData: true };

    this.observer.observe(this.el.nativeElement, config);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }
}
