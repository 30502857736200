export const environment = {
  production: false,
  serverAPI : 'https://wowapi.whizzy.in/',
  mysqlAPI: 'https://apidev.whizzy.in/whizzy/api/v2/',
  backednAPI: "https://apidev.whizzy.in/whizzy/api/v3/",
  evAPI: "https://evdevapi.whizzy.in/api/ev",
  appUrl: 'https://apidev.whizzy.in',
  vendorApi: 'https://vendordevapi.whizzy.in/api/',
  // vendorApi: 'http://localhost:5000/api/',

  googleMaps: {
    apiKey: 'AIzaSyA5M2jRJ-LvlQcJ0rdj6ssb_W3y-6ZxKAU',
    clusterImagePath: 'https://raw.githubusercontent.com/googlearchive/js-marker-clusterer/gh-pages/images/m',
  },
  firebase: {
    apiKey: "AIzaSyCQ9kNvPsmElMAAE9IhYhC6xgGvYT0HMTg",
    authDomain: "whizzy-uat-2021.firebaseapp.com",
    databaseURL: "https://whizzy-uat-2021-default-rtdb.firebaseio.com",
    projectId: "whizzy-uat-2021",
    storageBucket: "whizzy-uat-2021.appspot.com",
    messagingSenderId: "680756637922"
  },
  FB_TABLES: {
    CSA_CANCELLATION_REASONS_TABLE: 'cancellation_reasons_CSA',
    MONTHLY_BILLING_USERS: 'monthly_billing_users',
    CLUB_PENDING_VERIFICATIONS: 'club_pending_verifications',
    CLUB_VERIFICATION_DECLINE_REASONS: 'club_verification_decline_reasons',
  },
  RAZORPAY: {
    KEY_ID: 'rzp_live_jzypjLoXYk0SKH',
  },
};