import { Component, OnInit, Inject, ViewChildren, QueryList, ElementRef, Output, EventEmitter, } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'input-popup',
  templateUrl: './input-popup.component.html',
  styles: [
    `
  
    .example-radio-group {
      display: flex;
      flex-direction: column;
      margin: 15px 0;
    }
    
    .example-radio-button {
      margin: 5px;
    }`
  ]
})
export class InputPopupComponent implements OnInit {

  @Output() onAddCategory = new EventEmitter;
  @Output() onAddDropsToOrder = new EventEmitter;
  @Output() onMemberRejectReasonSelection = new EventEmitter;

  @ViewChildren('inputs') inputs: QueryList<ElementRef>;

  newItemForm: FormGroup;
  newCategoryForm: FormGroup;
  clubMemberRejectReason: FormControl;

  fb: FormBuilder = new FormBuilder();

  constructor(
    public dialogRef: MatDialogRef<InputPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar,

  ) { }

  ngOnInit() {

    if (this.data.view == 'add_merchant_menu_items_category') {
      this.initNewCategoryForm()
    }
  }

  ngAfterViewInit(): void {
    console.log(this.inputs);
    if (this.inputs.length) {
      this.inputs.first.nativeElement.focus()
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  markAllAsTouched(form: FormGroup) {
    var controls = Object.values(form.controls);
    controls.forEach((c: any) => {

      c.markAsTouched()
      if (c.controls) {
        this.markAllAsTouched(c)
      }
    });
  }

  markAllAsDirty(form: FormGroup) {
    var controls = Object.values(form.controls);
    controls.forEach((c: any) => {

      c.markAsDirty()
      if (c.controls) {
        this.markAllAsDirty(c)
      }
    });
  }

  markAllAsUntouched(form: FormGroup) {
    var controls = Object.values(form.controls);
    controls.forEach((c: any) => {

      c.markAsUntouched()
      if (c.controls) {
        this.markAllAsUntouched(c)
      }
    });
  }
  openSnackBar(message: string, action: string = 'OK', duration = 10000) {
    this.snackBar.open(message, action, {
      duration
    });
  }


  initNewCategoryForm() {
    this.newCategoryForm = this.fb.group({
      name: ['', Validators.required],
      is_visible_in_wow: [1,],
      status: ['Active', Validators.required],
    })
  }

  addCategory() {
    console.log(this.newCategoryForm.valid, this.newCategoryForm.value)
    if (this.newCategoryForm.invalid) {
      this.markAllAsTouched(this.newCategoryForm);
      this.markAllAsDirty(this.newCategoryForm);
      this.openSnackBar('Please fill the form correctly and try again.')
      return;
    }

    let body: any = {}
    body = this.newCategoryForm.value;
    this.onAddCategory.emit(body)
  }

  addDropsToOrder(drops) {
    this.onAddDropsToOrder.emit(drops)
  }

  clubMemberRejectReasonSelection(reason) {
    this.onMemberRejectReasonSelection.emit(reason)
  }

}
