import { Component, OnInit, ViewChild } from "@angular/core";
import { GlobalService } from "../../services/global.service";
import {
  MatTableDataSource,
  MatSort,
  MatPaginator,
  MatDatepicker,
} from "@angular/material";
import { SharedService } from "../../shared/service/shared.service";
import { AppComponent } from "../../app.component";
import { SelectAutocompleteComponent } from "mat-select-autocomplete";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../authentication/service/authentication.service";
import * as moment from "moment";
import { Moment } from "moment";
import { FormControl } from "@angular/forms";
import { AngularFireDatabase } from "@angular/fire/database";
import { CurrencyPipe, DatePipe, UpperCasePipe } from "@angular/common";
import { WindowRef } from "../../WindowRef";
import { MTableComponent } from "../../shared/components/mat-table/mat-table.component";
import { environment } from "../../../environments/environment";
import { take } from "rxjs/operators";
import { ObjStrProp } from "../../shared/pipes/shared.pipe";

@Component({
  selector: "merchant-invoice-report",
  templateUrl: "./merchant-invoice.component.html",
  styles: [],
})
export class MerchantInvoiceComponent implements OnInit {
  maxDate: Date;
  date2: any;
  date3: any;
  date = new FormControl();
  subscriptions$: any = [];
  dataSource: MatTableDataSource<any>;
  tableColumns = [];
  tableColumns2 = [
    {
      name: "created_date",
      title: "created_date",
      sort: 1,
      pipe: { name: DatePipe, args: ["dd.MM.yyyy"] },
    },
    {
      name: "created_time",
      title: "created_time",
      sort: 1,
      pipe: { name: DatePipe, args: ["HH:MM:SS"] },
    },
    {
      name: "drop_contact_name",
      title: "Member Name",
      sort: 2,
    },
    {
      name: "member_id",
      title: "Member ID",
      sort: 2,
    },
    {
      name: "drop_contact_number",
      title: "Mobile Number",
      sort: 2,
    },
    {
      name: "merchant_menu_fare",
      title: "Sub Total",
      sort: 2,
    },
    {
      name: "mess_charges",
      title: "Mess Charges",
      sort: 2,
    },
    {
      name: "tax_amount",
      title: "Tax",
      sort: 2,
    },
    {
      name: "order_fare",
      title: "Total",
      sort: 3,
    },
    {
      name: "id",
      title: "Order Id",
      sort: 3,
    },
  ];
  pageSizes = [];
  merchantList = <any>[];
  respInvoice: any = [];
  respOrders: any = [];
  selectedDate = {};
  exportConfig = {};
  exportConfig2 = {};
  isLoading: any = {};
  currentMerchant: any;
  page: any = {
    orders: 1,
  };
  @ViewChild("table") table: MTableComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("daterangepicker") rangePicker: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(SelectAutocompleteComponent)
  multiSelect: SelectAutocompleteComponent;

  process_fees_details;
  processingCharges: number;
  selectedMerchant: any;
  rzp1: any;
  options: any = {};
  paymentButtonDisabled: boolean = false;
  showGenerateInvoiceBtn: boolean = false;
  filteredMerchantList = <any>[];
  monthlyInvoiceList = <any>[];
  tblColumns: any = {};
  selectedDateRange: any = {};

  constructor(
    public winRef: WindowRef,
    private global: GlobalService,
    private shared: SharedService,
    private appComp: AppComponent,
    private router: Router,
    public auth: AuthenticationService,
    private db: AngularFireDatabase
  ) {
    let navigation = this.router.getCurrentNavigation();
    if (
      navigation.extras.state &&
      navigation.extras.state.fromMerchantMenuPage
    ) {
      let merchantData = sessionStorage.getItem("merchantData");
      if (merchantData) {
        try {
          this.currentMerchant = JSON.parse(merchantData);
        } catch (error) {
          console.log(
            "TCL: MerchantInvoiceComponent -> ngOnInit -> error",
            error
          );
        }
      }
    }
    console.log(
      "TCL: MerchantInvoiceComponent -> ngOnInit -> navigation",
      navigation
    );
  }

  async ngOnInit() {
    this.maxDate = new Date();

    this.tblColumns.invoiceList = [
      { title: "Merchant Name", name: "Merchant.merchant_business_name" },
      { title: "Invoice Date", name: "invoice_date_f" },
      {
        title: "Invoice Amount",
        name: "invoice_amount",
        pipe: { name: CurrencyPipe, args: ["INR"] },
      },
      {
        title: "Payment status",
        name: "payment_status",
        pipe: { name: UpperCasePipe },
        classes: function (row) {
          var returnStr = "";
          if (!row) return;
          let payment_status = row.payment_status
            ? row.payment_status.toLowerCase()
            : row.payment_status;
          if (payment_status == "pending") {
            returnStr = "badge badge-danger";
          }
          if (payment_status == "completed") {
            returnStr = "badge badge-success";
          }
          return returnStr;
        },
      },
    ];
    this.options = {
      key: environment.RAZORPAY.KEY_ID,
      amount: 0, // 100 paisa = INR 1
      name: "Whizzy Logistic Technologies Private Limited",
      description: "Whizzy Charges",
      image: "../../../assets/images/logo.png",
      handler: (response) => {
        console.log("TCL: MerchantInvoiceComponent -> response", response);
        this.paymentResponse(response);
      },
      modal: {
        ondismiss: () => {
          this.paymentButtonDisabled = false;
        },
      },
      prefill: {
        name: sessionStorage.getItem("userName"),
        email: "support@whizzy.in",
        contact: sessionStorage.getItem("userMobile"),
      },
      notes: {},
      theme: {
        color: "#3594E2",
      },
    };

    this.pageSizes = [10, 25, 50, 100, 200];

    let merchant_id;
    if (sessionStorage.profileId == "2") {
      merchant_id = sessionStorage.selectedMerchant
        ? sessionStorage.selectedMerchant
        : null;
    }
    if (sessionStorage.profileId == "3") {
      merchant_id = sessionStorage.merchantId;
    }
    if (sessionStorage.profileId == "6") {
      merchant_id = this.auth.decodedToken
        ? this.auth.decodedToken.parent_id
        : null;
    }
    if (merchant_id) {
      try {
        this.appComp.showLoader();

        let resp: any = await this.global
          .GetMerchantDetails(merchant_id)
          .toPromise();
        console.log("TCL: resp", resp);
        if (resp.status) {
          this.currentMerchant = resp.data.MerchantDetails[0];
        } else {
          this.shared.openSnackBar(resp.error.responseMessage);
        }
      } catch (error) {
        console.log("TCL: error", error);
        this.shared.openSnackBar(error.message);
      } finally {
        this.appComp.hideLoader();
      }
    }
    if (this.currentMerchant) {
      this.selectedMerchant = this.currentMerchant;
    }
    if (this.auth.isCsa() || this.auth.isAdmin()) {
      await this.GetAllMerchants();
    }

    this.process_fees_details = await this.db
      .object("ProcessingFees")
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  ngAfterViewInit() {
    console.log(this.rangePicker);
    // this.date.setValue(moment());
  }
  async getMerchantInvoice(paramObj) {
    let { start, end, limit = 999999 } = paramObj;
    let inputParams = {};
    if (!this.page["orders"]) {
      this.page["orders"] = 1;
    }
    if (!this.selectedMerchant) {
      this.shared.openSnackBar("Select merchant");
      return;
    }

    if (start && end) {
      inputParams = { ...paramObj };
      inputParams = {
        ...inputParams,
        date: start,
        merchant_id: this.selectedMerchant.merchant_id,
        page_no: this.page["orders"],
        limit,
      };
    }
    try {
      this.appComp.showLoader();
      this.isLoading["orders"] = true;
      let invoiceResp: any;
      if (
        this.selectedMerchant &&
        this.selectedMerchant.merchant_business_type
      ) {
        invoiceResp = await this.global
          .getMerchantInvoice(inputParams)
          .toPromise();
      } else {
        if (this.showGenerateInvoiceBtn) {
          invoiceResp = await this.global
            .generateAndGetMerchantMonthlyInvoices(inputParams)
            .toPromise();
        } else {
          invoiceResp = await this.global
            .getMerchantMonthlyInvoices(inputParams)
            .toPromise();
        }
      }
      this.appComp.hideLoader();
      if (invoiceResp.status) {
        this.showGenerateInvoiceBtn = false;

        ++this.page["orders"];

        if (this.page["orders"] == 1) {
          this.respInvoice = invoiceResp.data.data;
        } else {
          this.respInvoice = [...this.respInvoice, ...invoiceResp.data.data];
        }
        this.dataSource = new MatTableDataSource(this.respInvoice);
        if (
          this.selectedMerchant &&
          this.selectedMerchant.merchant_business_type
        ) {
          this.exportConfig = {
            fileName: `${this.selectedMerchant.merchant_business_name}_Invoice_${start}_${end}`,
          };
        } else {
          this.exportConfig = {
            fileName: `${this.selectedMerchant.merchant_business_name}_Invoice_${this.respInvoice[0]["invoice_date_f"]}`,
          };
        }
        // Set amount for payment
        if (this.respInvoice[0].invoice_amount) {
          let invoice_amount = parseFloat(this.respInvoice[0].invoice_amount);
          this.options.amount =
            100 *
            (invoice_amount +
              invoice_amount * 0.01 * this.process_fees_details.Razorpay);
          this.processingCharges =
            invoice_amount * 0.01 * this.process_fees_details.Razorpay;
          this.paymentButtonDisabled = false;
        }
        this.getTableColumns();
        setTimeout(() => {
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor =
            this.shared.customSortingDataAccessor();
        }, 1);
      } else {
        console.error(invoiceResp);
        this.shared.openSnackBar(invoiceResp.error.responseMessage);
        // this.dataSource = new MatTableDataSource([]);
        if (
          this.auth.isAdmin() &&
          !this.selectedMerchant.merchant_business_type
        ) {
          this.showGenerateInvoiceBtn = true;
        }
      }
      console.log(invoiceResp);
    } catch (error) {
      // this.dataSource = new MatTableDataSource([]);
      this.appComp.hideLoader();
      console.error(error);
      this.shared.openSnackBar(error.message);
    } finally {
      this.appComp.hideLoader();
      this.isLoading["orders"] = false;
    }
  }
  async getMerchantOrders(paramObj) {
    let { start, end, current, limit = 999999 } = paramObj;
    let inputParams = {};
    if (!this.page["orders"]) {
      this.page["orders"] = 1;
    }
    if (!this.selectedMerchant) {
      this.shared.openSnackBar("Select merchant");
      return;
    }
    this.respOrders = [];

    if (start && end) {
      inputParams = { ...paramObj };
      inputParams = {
        ...inputParams,
        date: current,
        merchant_id: this.selectedMerchant.merchant_id,
        page_no: this.page["orders"],
        limit,
      };
    }
    try {
      this.appComp.showLoader();
      this.isLoading["orders"] = true;
      const invoiceResp: any = await this.global
        .getMerchantOrders(inputParams)
        .toPromise();
      this.appComp.hideLoader();
      if (invoiceResp.status) {
        this.showGenerateInvoiceBtn = false;

        ++this.page["orders"];

        if (this.page["orders"] == 1) {
          this.respOrders = invoiceResp.data.data;
        } else {
          this.respOrders = invoiceResp.data.data;
        }

        this.respOrders.forEach((respOrder: any) => {
          const { parcel_charges, mess_charges_percent } = respOrder;
          respOrder.merchant_menu_fare += parseInt(parcel_charges || 0, 0);
          const mess_charges =
            (respOrder.merchant_menu_fare * parseFloat(mess_charges_percent)) /
            100;
          const merchant_menu_fare_total =
            respOrder.merchant_menu_fare + mess_charges;
          respOrder.mess_charges = mess_charges;
          respOrder.tax_amount = Math.round(merchant_menu_fare_total * 0.05);
          respOrder.order_fare = Math.round(
            merchant_menu_fare_total + respOrder.tax_amount
          );
          respOrder.created_date = respOrder.created_time;
        });

        this.dataSource = new MatTableDataSource(this.respOrders);
        if (
          this.selectedMerchant &&
          this.selectedMerchant.merchant_business_type
        ) {
          this.exportConfig2 = {
            fileName: `${this.selectedMerchant.merchant_business_name}_Invoice_${start}_${end}`,
          };
        } else {
          this.exportConfig2 = {
            fileName: `${this.selectedMerchant.merchant_business_name}_Invoice_${this.respOrders[0]["invoice_date_f"]}`,
          };
        }
        // Set amount for payment
        if (this.respOrders[0].invoice_amount) {
          const invoice_amount = parseFloat(this.respOrders[0].invoice_amount);
          this.options.amount =
            100 *
            (invoice_amount +
              invoice_amount * 0.01 * this.process_fees_details.Razorpay);
          this.processingCharges =
            invoice_amount * 0.01 * this.process_fees_details.Razorpay;
          this.paymentButtonDisabled = false;
        }
        this.getTableColumns();
        setTimeout(() => {
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor =
            this.shared.customSortingDataAccessor();
        }, 1);
      } else {
        console.error(invoiceResp);
        this.shared.openSnackBar(invoiceResp.error.responseMessage);
        // this.dataSource = new MatTableDataSource([]);
        if (
          this.auth.isAdmin() &&
          !this.selectedMerchant.merchant_business_type
        ) {
          this.showGenerateInvoiceBtn = true;
        }
      }
      console.log(invoiceResp);
    } catch (error) {
      // this.dataSource = new MatTableDataSource([]);
      this.appComp.hideLoader();
      console.error(error);
      this.shared.openSnackBar(error.message);
    } finally {
      this.appComp.hideLoader();
      this.isLoading["orders"] = false;
    }
  }

  ngOnDestroy() {
    try {
      // Unsubscribe from all the subscriptions
      this.subscriptions$.forEach((subs) => {
        subs.unsubsribe();
      });
    } catch (error) {
      console.error(error);
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  onDateRangeValueChange(e) {
    console.log(e);
    this.selectedDate["end"] = e.to;
    this.selectedDate["start"] = e.from;
  }

  fetchMerchantInvoice(event, dp = null) {
    event.preventDefault();
    console.log("fetchMerchantInvoice");
    this.page["orders"] = 1;
    this.respInvoice = [];
    this.dataSource = new MatTableDataSource(this.respInvoice);
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor =
        this.shared.customSortingDataAccessor();
    }, 1);
    this.getMerchantInvoice(this.selectedDate);
    if (dp) {
      try {
        setTimeout(() => {
          dp.close();
        }, 100);
      } catch (error) {
        console.error(
          "TCL: MerchantInvoiceComponent -> fetchMerchantInvoice -> error",
          error
        );
      }
    }
  }

  fetchMerchantOrders(event, dp = null) {
    event.preventDefault();
    console.log("fetchMerchantOrders");
    this.page["orders"] = 1;
    this.respInvoice = [];
    this.dataSource = new MatTableDataSource(this.respOrders);
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor =
        this.shared.customSortingDataAccessor();
    }, 1);
    this.getMerchantOrders(this.selectedDate);
    if (dp) {
      try {
        setTimeout(() => {
          dp.close();
        }, 100);
      } catch (error) {
        console.error(
          "TCL: MerchantInvoiceComponent -> fetchMerchantInvoice -> error",
          error
        );
      }
    }
  }

  merchantSelectionChanged(selected) {
    if (selected && Array.isArray(selected) && !selected.length) {
      return;
    }
    if (
      this.selectedMerchant &&
      this.selectedMerchant["merchant_id"] != selected
    ) {
    }
    if (!this.selectedMerchant) {
    }
    console.log(selected);
    this.selectedMerchant = this.merchantList.filter(
      (op) => op.merchant_id == selected
    )[0];
    if (!this.multiSelect) return;
    this.multiSelect.onDisplayString = () => {
      return this.selectedMerchant
        ? this.selectedMerchant.merchant_business_name ||
            this.selectedMerchant.merchant_name
        : this.multiSelect;
    };
  }

  async GetAllMerchants(reload: boolean = false) {
    try {
      const merResp: any = await this.shared.getMerchantList(reload);
      console.log({ merResp });
      if (merResp && merResp.length) {
        merResp.sort(
          (a, b) => a.merchant_business_name - b.merchant_business_name
        );
        this.merchantList = merResp;
        this.filteredMerchantList = this.merchantList.slice();
      } else {
        console.log("Unable to fetch merchants");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async onScroll() {
    console.log("onScroll called");

    this.getMerchantInvoice({ ...this.selectedDate });
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.setMinMaxDates();
  }

  setMinMaxDates() {
    this.selectedDate["start"] = this.date.value
      .startOf("month")
      .format("YYYY-MM-DD");
    this.selectedDate["end"] = this.date.value
      .endOf("month")
      .format("YYYY-MM-DD");
    console.log(
      "TCL: MerchantInvoiceComponent -> setMinMaxDates -> this.selectedDate",
      this.selectedDate
    );
    this.showGenerateInvoiceBtn = false;
  }
  setMinMaxDates2(date) {
    console.log("TCL: date", date);
    this.selectedDate["start"] = moment(date)
      .startOf("month")
      .format("YYYY-MM-DD");
    this.selectedDate["end"] = moment(date).endOf("month").format("YYYY-MM-DD");
    this.selectedDate["current"] = moment(date).format("YYYY-MM-DD");
    console.log(
      "TCL: MerchantInvoiceComponent -> setMinMaxDates -> this.selectedDate",
      this.selectedDate
    );
    this.showGenerateInvoiceBtn = false;
  }

  async paymentResponse(razorpayResp) {
    let user_id = sessionStorage.userId;
    try {
      let apiInput: any = {};
      apiInput = { ...razorpayResp, user_id, id: this.respInvoice[0].id };
      this.appComp.showLoader();

      let resp: any = await this.global
        .updateInvoicePaymentStatus(apiInput)
        .toPromise();
      console.log("TCL: resp", resp);
      if (resp.status) {
        this.shared.openSnackBar(resp.data.message);
        this.respInvoice = [];
        this.getMerchantInvoice(this.selectedDate);
      } else {
        this.shared.openSnackBar(resp.error.responseMessage);
      }
    } catch (error) {
      console.log("TCL: error", error);
      this.shared.openSnackBar(error.message);
      this.appComp.hideLoader();
    }
  }

  async makePayment() {
    try {
      let apiInput: any = {};
      apiInput.id = this.respInvoice[0].id;
      apiInput;
      this.appComp.showLoader();
      this.paymentButtonDisabled = true;
      let resp: any = await this.global
        .createInvoicePaymentOrder(apiInput)
        .toPromise();
      console.log("TCL: resp", resp);
      if (resp.status) {
        this.options.order_id = resp.data.data.id;
        this.rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
        this.rzp1.open();
      } else {
        this.shared.openSnackBar(resp.error.responseMessage);
        this.paymentButtonDisabled = false;
      }
    } catch (error) {
      this.paymentButtonDisabled = false;
      console.log("TCL: error", error);
      this.shared.openSnackBar(error.message);
    } finally {
      this.appComp.hideLoader();
    }
  }

  getTableColumns() {
    this.tableColumns = [
      {
        name: "MerchantInvoices.Order.created_time",
        title: "created_time",
        sort: 4,
        pipe: { name: DatePipe, args: ["medium"] },
      },
      {
        name: "MerchantInvoices.Order.order_id",
        title: "order_id",
        sort: 2,
      },
      {
        name: "MerchantInvoices.Order.pickup_contact_more_info",
        title: "pickup_contact_more_info",
        sort: 2,
      },
      {
        name: "MerchantInvoices.Order.accepted_to_pickup",
        title: "Accepted to pickup",
        sort: 3,
      },
      {
        name: "MerchantInvoices.Order.OrderDropContacts.distance_round",
        title: "distance",
        sort: 3,
      },
      {
        name: "MerchantInvoices.Order.OrderDropContacts.order_status",
        title: "status",
        sort: 3,
      },
      {
        name: "MerchantInvoices.Order.pickup_full_address",
        title: "pickup_address",
        sort: 5,
      },
      {
        name: "MerchantInvoices.Order.OrderDropContacts.drop_full_address",
        title: "drop_address",
        sort: 8,
      },
      {
        name: "MerchantInvoices.Order.OrderDropContacts.drop_contact_name",
        title: "drop_contact_name",
        sort: 6,
      },
      {
        name: "MerchantInvoices.Order.OrderDropContacts.drop_contact_number",
        title: "drop_mobile#",
        sort: 7,
      },
    ];

    if (this.selectedMerchant && this.selectedMerchant.merchant_business_type) {
      this.tableColumns = [
        {
          name: "created_time",
          title: "created_time",
          sort: 4,
        },
        {
          name: "time",
          title: "completion_time",
          sort: 4,
        },
        {
          name: "order_id",
          title: "order_id",
          sort: 2,
        },
        {
          name: "distance_round",
          title: "distance",
          sort: 3,
        },
        {
          name: "pickup_full_address",
          title: "pickup_address",
          sort: 5,
        },
        {
          name: "drop_full_address",
          title: "drop_address",
          sort: 8,
        },
        {
          name: "drop_contact_name",
          title: "drop_contact_name",
          sort: 6,
        },
        {
          name: "drop_contact_number",
          title: "drop_mobile#",
          sort: 7,
        },
      ];
    }

    return this.tableColumns;
  }

  onDateChanged(e) {
    console.log(e);
    this.selectedDateRange["end"] = e.to;
    this.selectedDateRange["start"] = e.from;
  }

  async getMerchantMonthlyInvoicesList() {
    try {
      let apiInput: any = {};
      this.appComp.showLoader();
      apiInput.start = this.selectedDateRange.start;
      apiInput.end = this.selectedDateRange.end;

      let resp: any = await this.global
        .getMerchantMonthlyInvoicesList(apiInput)
        .toPromise();
      console.log("TCL: resp", resp);
      if (resp.status) {
        this.monthlyInvoiceList = resp.data.data;
      } else {
        this.shared.openSnackBar(resp.error.responseMessage);
      }
    } catch (error) {
      console.log("TCL: error", error);
      this.shared.openSnackBar(error.message);
    } finally {
      this.appComp.hideLoader();
    }
  }
}
