import { Component, Inject, HostListener } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

export interface ConfirmData {
    confirmationTitle: string;
    confirmationText: string;
    confirmationMethod: string;
    confirmationProceed: boolean;
    yesText?:string,
    noText?:string,
    hideNo?:boolean,

}

@Component({
    selector: 'app-shared-confirmation',
    templateUrl: 'shared-confirmation.html',
})
export class AppSharedConfirmationComponent {
    yesText: string = 'Yes'
    noText: string = 'No'

    constructor(
        public dialogRef: MatDialogRef<AppSharedConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmData
    ) { }

    onConfirmNo(): void {
        this.dialogRef.close();
    }

    @HostListener('window:keyup.esc') onKeyUp() {
        this.dialogRef.close();
    }
}
