import { Pipe, PipeTransform, Injector } from '@angular/core';

@Pipe({
  name: 'removeUnderscore'
})
export class RemoveUnderscorePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    try {

      value = value.replace(/[_-]/g, ' ')

      var pieces = value.split(" ");
      for (var i = 0; i < pieces.length; i++) {
        var j = pieces[i].charAt(0).toUpperCase();
        pieces[i] = j + pieces[i].substr(1).toLowerCase();
      }
      return pieces.join(" ");
    } catch (error) {
      console.log("TCL: RemoveUnderscorePipe -> error", error);
      return value;
    }
  }

}
@Pipe({
  name: 'toDate'
})
export class ToDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    try {
      let date = new Date(value)
      return date;
    } catch (error) {
      console.log("TCL: ToDatePipe -> error", error);
      return value;
    }
  }

}

@Pipe({name: 'log'})
export class LogPipe implements PipeTransform {
    public transform(value: object): any {
        console.log(value);
        return value;
    }
}
/**
 * Access object properties by passing property path as string.
 * let a = { b: {c:d{4}}};
 * {{a | objstrprop:'b.c.d'}}
 */
@Pipe({ name: 'objstrprop' })
export class ObjStrProp implements PipeTransform {
  public transform(value: object, str: string): any {
    let i = 0;
    let obj = value;
    if(!str) return obj;
    let s = str.split('.');
    while (obj && i < s.length)
      obj = obj[s[i++]];
    return obj
  }
}

// https://stackoverflow.com/a/46910713/6613333
@Pipe({
  name: 'dynamicPipe'
})
export class DynamicPipe implements PipeTransform {

  public constructor(private injector: Injector) {
  }

  transform(value: any, pipeToken: any, pipeArgs: any[] = []): any {
    if (!pipeToken) {
      return value;
    }
    else {
      let pipe = this.injector.get(pipeToken);
      if(typeof pipeArgs == 'string') {
        pipeArgs = [pipeArgs];
      }
      return pipe.transform(value, ...pipeArgs);
    }
  }
}