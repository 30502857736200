import { ErrorHandler, NgModule } from '@angular/core';
import { SharedService } from './service/shared.service';
import { MenuItems } from './menu-items/menu-items';
import {
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective
} from './accordion';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DemoMaterialModule } from '../demo-material-module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DatePipe,
  CurrencyPipe,
  TitleCasePipe,
  UpperCasePipe,
} from '@angular/common';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../environments/environment';
import { WindowRef } from '../WindowRef';
import { BsModalService, BsModalRef, ModalModule, TabsModule } from 'ngx-bootstrap';
import {
  RemoveUnderscorePipe,
  ToDatePipe,
  ObjStrProp,
  LogPipe,
  DynamicPipe,
} from './pipes/shared.pipe';
import { PopupComponent } from './popup/popup.component';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { TopScrollDirective } from './directives/top-scroll.directive';
import {
  MMDDDateDirective,
  CustomDateFormat2,
  SQLDateFormat,
  DDMMMYYYYFormat,
  DropZoneDirective,
} from './directives/shared.directive';
import { InputPopupComponent } from './popup/input-popup/input-popup.component';
import { CommonModule } from '@angular/common';
import { MTableComponent } from './components/mat-table/mat-table.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { OrderStatusProgressbarComponent } from './components/order-status-progressbar/order-status-progressbar.component';
import { TableComponent } from './components/table/table.component';
import { ReallotOrderComponent } from './components/reallot-order/reallot-order.component';
import { ReassignOrderComponent } from './components/reassign-order/reassign-order.component';
import { MerchantPickupLocationSelectorComponent } from './components/merchant-pickup-location-selector/merchant-pickup-location-selector.component';
import { FileUploadDNDComponent } from './components/file-upload-dnd/file-upload-dnd.component';
import { InputErrorComponent } from './components/input-error/input-error.component';
import { ItemImageGalleryComponent } from './components/item-image-gallery/item-image-gallery.component';
import { DialogCloseBtnComponent } from './components/dialog-close-btn/dialog-close-btn.component';
import { ApiErrorsComponent } from './components/api-errors/api-errors.component';
import { MerchantInvoiceComponent } from '../csa/merchant-invoice/merchant-invoice.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { OrderDropsInputComponent } from './components/order-drops-input/order-drops-input.component';
import { ChartistModule } from 'ng-chartist';
import { HighchartsChartModule } from 'highcharts-angular';
import { CustomErrorHandler } from './classes/CustomErrorHandler';
import { NgSelectModule } from '@ng-select/ng-select';
import { TimeagoModule } from 'ngx-timeago';
import { NgxLightboxGalleryModule } from 'ngx-lightbox-gallery';

@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    RemoveUnderscorePipe,
    PopupComponent,
    ToDatePipe,
    ObjStrProp,
    TopScrollDirective,
    MMDDDateDirective,
    CustomDateFormat2,
    SQLDateFormat,
    DDMMMYYYYFormat,
    InputPopupComponent,
    MTableComponent,
    LogPipe,
    DynamicPipe,
    DateRangeComponent,
    OrderStatusProgressbarComponent,
    TableComponent,
    ReallotOrderComponent,
    ReassignOrderComponent,
    MerchantPickupLocationSelectorComponent,
    FileUploadDNDComponent,
    DropZoneDirective,
    InputErrorComponent,
    ItemImageGalleryComponent,
    DialogCloseBtnComponent,
    ApiErrorsComponent,
    MerchantInvoiceComponent,
    OrderDropsInputComponent,


  ],
  imports: [
    CommonModule,
    HighchartsChartModule,
    ChartistModule,
    MatSelectFilterModule,
    MatDialogModule,
    DemoMaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    TimeagoModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMaps.apiKey,
      libraries: ['geometry', 'places', 'visualization']
    }),
    ModalModule.forRoot(),
    AgmJsMarkerClustererModule,
    TabsModule.forRoot(),
    NgxLightboxGalleryModule,
  ],
  exports: [
    CommonModule,
    HighchartsChartModule,
    ChartistModule,
    MatSelectFilterModule,
    MatDialogModule,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    TimepickerModule,
    BsDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    TimeagoModule,
    FlexLayoutModule,
    DemoMaterialModule,
    AgmCoreModule,
    ModalModule,
    RemoveUnderscorePipe,
    PopupComponent,
    AgmJsMarkerClustererModule,
    LogPipe,
    DynamicPipe,
    TopScrollDirective,
    MMDDDateDirective,
    CustomDateFormat2,
    SQLDateFormat,
    DDMMMYYYYFormat,
    InputPopupComponent,
    ToDatePipe,
    ObjStrProp,
    DateRangeComponent,
    OrderStatusProgressbarComponent,
    TableComponent,
    ReallotOrderComponent,
    ReassignOrderComponent,
    MerchantPickupLocationSelectorComponent,
    MTableComponent,
    FileUploadDNDComponent,
    DropZoneDirective,
    InputErrorComponent,
    ItemImageGalleryComponent,
    DialogCloseBtnComponent,
    DialogCloseBtnComponent,
    ApiErrorsComponent,
    MerchantInvoiceComponent,
    OrderDropsInputComponent,
    TabsModule,
    NgxLightboxGalleryModule,
  ],
  providers: [
    {provide: ErrorHandler, useClass: CustomErrorHandler},
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, disableClose: true } },
    MenuItems,
    SharedService,
    DatePipe,
    TitleCasePipe,
    UpperCasePipe,
    WindowRef,
    BsModalService,
    BsModalRef,
    CurrencyPipe,
    RemoveUnderscorePipe,
    ObjStrProp,
  ],
  entryComponents:[
    InputPopupComponent,
    DateRangeComponent,
    TableComponent,
    MerchantPickupLocationSelectorComponent,
    MTableComponent,
    FileUploadDNDComponent,
    InputErrorComponent,
    ItemImageGalleryComponent,
    DialogCloseBtnComponent,
    ApiErrorsComponent,
    MerchantInvoiceComponent,
    OrderDropsInputComponent,
  ]
})
export class SharedModule { }
