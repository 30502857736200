import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { SharedService } from '../../service/shared.service';
import { GlobalService } from '../../../services/global.service';


@Component({
  selector: 'item-image-gallery',
  templateUrl: './item-image-gallery.component.html',
  styles: [
    `
.item-image-gallery-container .selected {
  outline: 6px solid #1e88e5;
  transition: all 0.1s ease;
}

.image-container {
  max-height: 300px;
  overflow: auto;
}

.gal {
  -webkit-column-count: 3; /* Chrome, Safari, Opera */
  -moz-column-count: 3; /* Firefox */
  column-count: 3;
}
.gal img {
  width: 100%;
  padding: 7px 0;
}
@media (max-width: 500px) {
  .gal {
    -webkit-column-count: 1; /* Chrome, Safari, Opera */
    -moz-column-count: 1; /* Firefox */
    column-count: 1;
  }
}
    `

  ]
})
export class ItemImageGalleryComponent implements OnInit {

  @Output() imageSelect = new EventEmitter();
  @Output() imageUnselect = new EventEmitter();

  isLoading: boolean = false;
  selectedImage: any;
  images: any = []
  categories: any = []
  selectedCategory: number;
  page_no: number = 1;

  constructor(
    private global: GlobalService,
    private shared: SharedService,
  ) { }

  ngOnInit() {
    this.getCategories();
  }

  onClickSearch() {
    this.page_no = 1;
    this.searchImages();
  }

  async searchImages() {
    try {
      let apiInput: any = {};
      apiInput.category_id = this.selectedCategory;
      apiInput.page_no = this.page_no;
      // this.appComp.showLoader();
      this.isLoading = true;
      if (this.page_no == 1) {
        this.images = [];
      }
      let resp: any = await this.global.searchItemImages(this.selectedCategory, apiInput).toPromise();
      console.log("TCL: resp", resp);
      if (resp.status) {
        this.page_no++;
        let images = resp.data.data;
        this.images = [...this.images, ...images]
      } else {
        this.shared.openSnackBar(resp.error.responseMessage)
      }
    } catch (error) {
      console.log("TCL: error", error)
      this.shared.openSnackBar(error.message);

    } finally {
      // this.appComp.hideLoader();
      this.isLoading = false;


    }

  }

  async getCategories() {
    try {
      let apiInput = {};
      // this.appComp.showLoader();

      let resp: any = await this.global.getItemImageCatalogCategories(apiInput).toPromise();
      console.log("TCL: resp", resp);
      if (resp.status) {
        this.categories = resp.data.data;
      } else {
        this.shared.openSnackBar(resp.error.responseMessage)
      }
    } catch (error) {
      console.log("TCL: error", error)
      this.shared.openSnackBar(error.message);

    } finally {
      // this.appComp.hideLoader();

    }
  }

  onCatSelect(cat) {
    this.selectedCategory = cat;
  }

  onScroll() {
    this.searchImages();
  }



  imgClicked(img) {
    console.log("TCL: ItemImageGalleryComponent -> imgClicked -> img", img)
    if (this.selectedImage && this.selectedImage.id == img.id) {
      // unselect 
      this.selectedImage = null;
      this.imageUnselect.emit()
      return;
    }
    this.selectedImage = img;
    this.imageSelect.emit(this.selectedImage)
  }
}
