import { Injectable } from '@angular/core';


export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: '',
    name: 'Personal',
    type: 'saperator',
    icon: 'av_timer'
  },
  {
    state: 'dashboards',
    name: 'Dashboards',
    type: 'link',
    icon: 'av_timer'
  },
  {
    state: 'rosters',
    name: 'Rosters',
    type: 'sub',
    icon: 'reorder',
    children: [
      { state: 'rosters-list', name: 'List of Rosters', type: 'link' },
      { state: 'todays-rosters', name: 'Todays Rosters', type: 'link' },
      { state: 'merchantslist', name: 'List of Merchants', type: 'link' }
    ]
  },
  {
    state: 'requests',
    name: 'Requests',
    type: 'sub',
    icon: 'list',
    children: [
      { state: 'newrequest', name: 'New Requests', type: 'link' },
      { state: 'myrequests', name: 'My Requests', type: 'link' }
    ]
  },
  {
    state: 'addressbook',
    name: 'Address Book',
    type: 'link',
    icon: 'contacts'
  },
  {
    state: 'administration',
    name: 'Administration',
    type: 'sub',
    icon: 'account_box',
    children: [
      { state: 'users', name: 'Users', type: 'link' }
    ]
  },
  {
    state: 'billing',
    name: 'Billing',
    type: 'link',
    icon: 'description'
  },
  {
    state: 'merchant',
    name: 'Profile',
    type: 'link',
    icon: 'account_box'
  }
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
