import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'order-status-progressbar',
  templateUrl: './order-status-progressbar.component.html',
  styles: [
    `
:host {
  width: 100%;
}
.step {
  list-style: none;
  margin: .2rem 0;
  width: 100%;
}

.step .step-item {
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  margin-top: 0;
  min-height: 1rem;
  position: relative; 
  text-align: center;
}

.step .step-item:not(:first-child)::before {
  background: #0069d9;
  content: "";
  height: 2px;
  left: -50%;
  position: absolute;
  top: 9px;
  width: 100%;
}

.step .step-item span {
  /* color: #acb3c2; */
  display: inline-block;
  padding: 20px 10px 0;
  text-decoration: none;
}
.step .step-item .time small {
  /* color: #acb3c2; */
}
.step .step-item span::before {
  background: #0069d9;
  border: .1rem solid #fff;
  border-radius: 50%;
  content: "";
  display: block;
  height: .9rem;
  left: 50%;
  position: absolute;
  top: .2rem;
  transform: translateX(-50%);
  width: .9rem;
  z-index: 1;
}

.step .step-item.active span::before {
  background: #0069d9;
  border: .1rem solid #0069d9;
}

.step .step-item.active ~ .step-item::before {
  background: #e7e9ed;
}

.step .step-item.active ~ .step-item span::before {
  background: #e7e9ed;
}

.step-item.inactive::before,.step-item.inactive span::before {
  background: #e7e9ed !important;
}
    `
  ]
})
export class OrderStatusProgressbarComponent implements OnInit {

  _order: any = {};
  get order() { return this._order; }

  @Input()
  set order(val: any) {
    this._order = val;
    if (this._order.OrderStatusLogs) {
      let statusLog = this._order.OrderStatusLogs;
      statusLog.forEach((log) => {
        let to = log.to.toLowerCase().replace(/ /g, "_");
        if (!this.timings[to]) {
          this.timings[to] = {}
        }
        // it will take only last time if we have same order status multiple times. 
        // ex: if reassignment then multiple accepted by driver then only take last value
        this.timings[to].time = log.time;
      })
    }
  }
  timings: any = {};
  steps: any = [];
  constructor() { }

  ngOnInit() {
    this.steps = [
      { name: 'accepted_by_driver', label: 'Accepted' },
      { name: 'arrived_at_pickup', label: 'Arrived at pickup' },
      { name: 'start_trip', label: 'Whizzer on his way' },
      { name: 'arrived_at_drop', label: 'Arrived at drop' },
      { name: 'completed', label: 'Completed' },
    ]
  }

  canActive(status: string) {
    let order_status = this.order.order_status;
    if (order_status) {
      order_status = order_status.toLowerCase().replace(/ /g, '_');
    }
    let classes: any = {};
    classes.active = 'active'
    classes.inactive = 'inactive'
    switch (order_status) {
      case 'accepted_by_driver':
        if (status == order_status)
          return classes.active;
        break;
      case 'arrived_at_pickup':
        if (status == order_status)
          return classes.active;

        break;
      case 'start_trip':
        if (status == order_status)
          return classes.active;

        break;
      case 'arrived_at_drop':
        if (status == order_status)
          return classes.active;

        break;
      case 'completed':
        if (status == order_status)
          return classes.active;

        break;
      default:
        return classes.inactive;
    }

  }
}
