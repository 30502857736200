import { Component, OnInit, ViewChild, ElementRef, NgZone, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SharedService } from '../../service/shared.service';
import { GlobalService } from '../../../services/global.service';
import { CurrencyPipe } from '@angular/common';
declare var google: any;

export interface User {
  name: string;
}
@Component({
  selector: 'order-drops-input',
  templateUrl: './order-drops-input.component.html',
  styles: []
})
export class OrderDropsInputComponent implements OnInit {

  @ViewChild('dropMapSearch') dropMapSearchElementRef: ElementRef;

  @Output() dropDeleted = new EventEmitter();
  @Output() dropAdded = new EventEmitter();
  @Output() dropsUpdated = new EventEmitter();

  @Input('merchant_id') merchant_id;

  dropsForm: FormGroup = new FormGroup({})
  referLocationDistance: any;
  referLocationLatitude: any;
  referLocationLongitude: any;
  dropLat = 17.3850
  dropLng = 78.4867
  filteredOptions: Observable<User[]>;
  zoom = 15
  contacts: any = []
  filteredContacts: any = []
  dropInputs: any;
  drops: any = []
  dropTableColumns: any = []
  showMap = false
  paymentModes: any = {};

  constructor(
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private db: AngularFireDatabase,
    private global: GlobalService,
    public shared: SharedService,
  ) { }

  ngOnInit() {


    this.paymentModes = [
      {
        label: 'No',
        value: '',
      },
      {
        label: 'Yes',
        value: 'cod',
      },
    ];
    this.dropTableColumns = [
      {
        name: 'drop_contact_name',
        title: 'drop_contact_name',
        sort: 1,
      },
      {
        name: 'drop_contact_number',
        title: 'drop_contact_number',
        sort: 1,
      },
      {
        name: 'drop_full_address',
        title: 'drop_full_address',
        sort: 1,
      },
      {
        name: 'collect_payment',
        title: 'collect_payment?',
        sort: 1,
      },
      {
        name: 'drop_payment_amount',
        title: 'amount to be collected',
        pipe: { name: CurrencyPipe, args: ['INR'] },
        sort: 1,
      },
      {
        name: 'action',
        // hide:true,
        title: 'Actions',
        actions: [
          {
            function: 'deleteDrop',
            icon: 'delete',
            color: 'warn',
            title: '',
            classes: [
              'action',
            ],
          },
        ],
        sort: 99,
      },

    ];


    this.dropInputs = {
      drop_contact_name: ['', [Validators.required]],
      drop_items: ['', [Validators.required]],
      drop_contact_number: ['', [Validators.required]],
      drop_contact_more_info: [],
      drop_search_address: [],
      drop_full_address: ['', [Validators.required]],
      drop_latitude: [, [Validators.required]],
      drop_longitude: [, [Validators.required]],
      drop_landmark: [],
      sort_order: [''],
      drop_payment_collection_mode: [],
      drop_payment_amount: [, [Validators.min(1), Validators.max(9999)]],
      contact: [],
    };

    this.initDropsForm();
    this.initRefLocation();
    this.loadMap();
    this.getContacts()
  }

  initDropsForm() {

    this.dropsForm = this.fb.group(this.dropInputs)

    this.dropsForm.get('drop_payment_collection_mode').valueChanges.subscribe((value) => {
      let paymentAmount = this.dropsForm.get('drop_payment_amount')

      if (value == 'cod') {
        paymentAmount.setValidators([Validators.required, Validators.min(1), Validators.max(9999)])
      } else {
        paymentAmount.patchValue(null);
        paymentAmount.setValidators([]);
        paymentAmount.setValidators([Validators.min(1), Validators.max(9999)]);
      }
      paymentAmount.updateValueAndValidity();
    })
  }

  addDrop() {
    if (this.dropsForm.invalid) {
      this.shared.openSnackBar('Please fill the form correctly and try again')
      this.shared.markAllAsDirty(this.dropsForm)
      this.shared.markAllAsTouched(this.dropsForm)
      return;
    }
    let newDrop = this.dropsForm.value;
    newDrop.collect_payment = (this.dropsForm.value.drop_payment_collection_mode == 'cod' ? 'Yes' : 'No');
    this.drops = [...this.drops, newDrop];
    this.initDropsForm();
    this.dropAdded.emit(this.drops)
    this.dropsUpdated.emit(this.drops)
  }

  loadMap() {
    this.mapsAPILoader.load().then(() => {

      const dropAutocomplete = new google.maps.places.Autocomplete(this.dropMapSearchElementRef.nativeElement, {
        types: [] // 'address'
      });
      dropAutocomplete.setComponentRestrictions({ 'country': ['in'] });
      dropAutocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = dropAutocomplete.getPlace();
          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          const dropLatTemp = place.geometry.location.lat();
          const dropLngTemp = place.geometry.location.lng();

          const dropDistance = this.calculateDistance(dropLatTemp, dropLngTemp);

          if (dropDistance > this.referLocationDistance) {
            this.shared.openSnackBar('Currently We do not serve this area');
            this.dropsForm.get('drop_search_address').patchValue('');
          } else {
            const currentLatlng = new google.maps.LatLng(dropLatTemp, dropLngTemp);
            this.dropLat = dropLatTemp;
            this.dropLng = dropLngTemp;
            this.getDropMarkerPlace(currentLatlng);
          }

        });
      });

    });
  }


  calculateDistance(latitude: any, longitude: any) {
    const R = 6371; // km
    const dLat = (latitude - this.referLocationLatitude) * Math.PI / 180;
    const dLon = (longitude - this.referLocationLongitude) * Math.PI / 180;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos((this.referLocationLatitude) * Math.PI / 180) *
      Math.cos((latitude) * Math.PI / 180);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  async initRefLocation() {
    let data: any = await this.db.object('Reference_Location').valueChanges().pipe(take(1)).toPromise()
    if (data) {
      this.referLocationDistance = data.wow_distance;
      this.referLocationLatitude = data.latitude;
      this.referLocationLongitude = data.longitude;
    }
  }

  getDropMarkerPlace(currentLatlng: any) {
    this.patchDropLatLng(this.dropLat, this.dropLng);
    new google.maps.Geocoder().geocode({ 'latLng': currentLatlng },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            this.dropsForm.get('drop_search_address').patchValue('');
            this.dropsForm.get('drop_full_address').patchValue(results[0].formatted_address);
          }
        }
      });
  }

  patchDropLatLng(latitude, longitude) {
    this.dropsForm.get('drop_latitude').patchValue(latitude);
    this.dropsForm.get('drop_longitude').patchValue(longitude);
  }

  displayFn(user?: User): string | undefined {
    return user ? user.name : undefined;
  }

  dropMarkerDragEnd(event) {
    const dropLatTemp = event.coords.lat;
    const dropLngTemp = event.coords.lng;
    const distance = this.calculateDistance(dropLatTemp, dropLngTemp);
    if (distance > this.referLocationDistance) {
      this.shared.openSnackBar('Currently We do not serve this area');
    } else {
      const currentLatlng = new google.maps.LatLng(dropLatTemp, dropLngTemp);
      this.dropLat = dropLatTemp;
      this.dropLng = dropLngTemp;
      this.getDropMarkerPlace(currentLatlng);
    }
  }

  async getContacts() {
    const merchantData = {
      user_id: sessionStorage.getItem('userId'),
      merchant_id: this.merchant_id,
      Offset: 0,
      PageCount: 99999
    };
    try {

      let response: any = await this.global.GetMerchantCustomers(merchantData).toPromise()
      if (response.status === 'success') {
        this.contacts = response.data.Customers;
        this.filteredContacts = this.contacts.slice();
      } else {
        this.shared.openSnackBar('No Contacts Available', 'Close');
      }
    } catch (error) {
      this.shared.openSnackBar(error.Message);

    }

  }

  onCustomerSelection(event) {
    console.log("TCL: OrderDropsInputComponent -> onCustomerSelection -> event", event)
    let customer: any = {};
    if (event.value) {
      customer.customer_name = event.value.customer_name
      customer.customer_phone_number = event.value.customer_phone_number
      customer.customer_address = event.value.customer_address
      customer.customer_landmark = event.value.customer_landmark
      customer.customer_latitude = event.value.customer_latitude
      customer.customer_longitude = event.value.customer_longitude
    } else {
      customer.customer_name = ''
      customer.customer_phone_number = ''
      customer.customer_address = ''
      customer.customer_landmark = ''
      customer.customer_latitude = ''
      customer.customer_longitude = ''
    }
    this.dropsForm.patchValue({
      drop_contact_name: customer.customer_name,
      drop_contact_number: customer.customer_phone_number,
      drop_full_address: customer.customer_address,
      drop_landmark: customer.customer_landmark,
      drop_latitude: customer.customer_latitude,
      drop_longitude: customer.customer_longitude,
    })

  }


  deleteDrop(drop) {
    console.log("TCL: OrderDropsInputComponent -> deleteDrop -> drop", drop)
    let index = this.drops.indexOf(drop)
    console.log("TCL: OrderDropsInputComponent -> deleteDrop -> index", index)
    this.drops.splice(index, 1)
    this.drops = [...this.drops]
    this.dropDeleted.emit(this.drops)
    this.dropsUpdated.emit(this.drops)
  }

  onActionClicked(event: any) {
    console.log(event);
    let action = event.action.function;
    switch (action) {
      case 'deleteDrop':
        this.deleteDrop(event.row);
        break;

      default:
        break;
    }
  }

  public getDrops() {
    return this.drops;
  }

  addDropsToOrder() {


  }

  get dropArr(): FormArray {
    let group = this.dropsForm.get('drop_contact_details') as FormArray;
    return group;
  }
}
