import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { CdkOverlayOrigin } from "@angular/cdk/overlay";
import { Observable, fromEvent, Subject } from "rxjs";
import {
  switchMap,
  startWith,
  debounceTime,
  share,
  takeUntil,
  filter,
  switchMapTo
} from "rxjs/operators";

@Component({
  selector: "popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupComponent implements OnDestroy, OnInit {
  @Input() CdkOverlayOrigin: CdkOverlayOrigin;
  @Input() data: string;
  @Output() close = new EventEmitter<any>();
  @Output() open = new EventEmitter<any>();

  @ViewChild("dialog") dialog: ElementRef;
  isOpened = false;
  destroy$ = new Subject<any>();

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    console.log("ngoninit popup");

    const CdkOverlayOriginEl = this.CdkOverlayOrigin.elementRef.nativeElement;

    // open popup if mouse stopped in CdkOverlayOriginEl (for short time).
    // If user just quickly got over CdkOverlayOriginEl element - do not open
    const open$ = fromEvent(CdkOverlayOriginEl, "mouseenter")
      .filter(() => !this.isOpened)
      .pipe(
        switchMap(enterEvent =>
          fromEvent(document, "mousemove")
            .pipe(startWith(enterEvent))

            .filter(event => CdkOverlayOriginEl === event["target"])
        )
      )
      .pipe(share());
    open$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.changeState(true));

    // close if mouse left the CdkOverlayOriginEl and dialog(after short delay)
    const close$ = fromEvent(document, "mousemove")
      .pipe(filter(() => this.isOpened))
      .pipe(
        filter(event =>
          this.isMovedOutside(CdkOverlayOriginEl, this.dialog, event)
        )
      );

    open$
      .pipe(takeUntil(this.destroy$))
      .pipe(switchMapTo(close$))
      .subscribe(() => {
        this.changeState(false);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  connectedOverlayDetach() {
    this.changeState(false);
  }

  private changeState(isOpened: boolean) {
    this.isOpened = isOpened;
    isOpened ? this.open.emit() : this.close.emit();
    this.changeDetectorRef.markForCheck();
  }

  private isMovedOutside(CdkOverlayOriginEl, dialog, event): boolean {
    return !(
      CdkOverlayOriginEl.contains(event["target"]) ||
      dialog.nativeElement.contains(event["target"])
    );
  }
}
