import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'api-errors',
  templateUrl: './api-errors.component.html',
  styles: [
` 
  :host {
    width: 100%;
  }

`

  ]
})
export class ApiErrorsComponent implements OnInit {

  @Input()
  get apiResponse() { return this._apiResponse; };
  set apiResponse(value) { 
    this._apiResponse = value 
    this.getValidationErrors();

  }

  columns: any = []
  showValidationErrors:boolean;
  showErrorMsg:boolean = false;
  _apiResponse:any;
  constructor() { }

  ngOnInit() {
    this.columns =  [
      { name: 'fieldName', path: 'fieldName',},
      { name: 'message', path: 'message',},
    ];

    this.getValidationErrors();
  }

  getValidationErrors() {
    if(this.apiResponse && this.apiResponse.error ) {
      if(this.apiResponse.error.errors && Array.isArray(this.apiResponse.error.errors)) {
        this.showValidationErrors = true;
        return;
      }
      if(this.apiResponse.error.responseMessage) {
        this.showErrorMsg = true;
        return;
      }
    }
  }

}
