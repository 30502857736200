import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { MatSnackBar, Sort, MatDialog, PageEvent } from '@angular/material';
import { GlobalService } from '../../services/global.service';
import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { GeoFire } from 'geofire';
import { AngularFireDatabase } from '@angular/fire/database';
import { DatePipe, KeyValue } from '@angular/common';
import { AppSharedConfirmationComponent } from '../../shared/shared-confirmation';
import { MapsAPILoader } from '@agm/core';
import * as _moment from 'moment';
import { InputPopupComponent } from '../popup/input-popup/input-popup.component';
import { take } from 'rxjs/operators';
import { Options } from 'highcharts';
declare var google: any;

const moment = _moment;
@Injectable()
export class SharedService {
  ORDER_STATUSES = {
    LOOKING_FOR_DRIVERS: 'Looking For Drivers',
    ACCEPTED_BY_DRIVER: 'Accepted By Driver',
    ORDER_CANCELLED: 'Order Cancelled',
    DRIVER_CANCELLED: 'Driver Cancelled',

    CANCELED: 'Canceled',
    COMPLETED: 'Completed',
    NO_VEHICLES_AVAILABLE: 'No Vehicles Available',
    TRIP_ENDED: 'trip_ended',
    ARRIVED_AT_DROP: 'arrived_at_drop',
    ARRIVED_AT_PICKUP: 'arrived_at_pickup',
    START_TRIP: 'start_trip',
    CUSTOMER_CANCELLED: 'customer_cancelled',
    ORDER_CANCELLED_: 'order_cancelled',
    CSA_CANCELLED: 'csa_cancelled',
    RELEASE_WHIZZER: 'Release Whizzer',
    ALLOTED: 'Alloted',
    PAYMENT_SESSION_EXPIRED: 'payment_session_expired',
    TRANSACTION_CANCELLED: 'transaction_cancelled',
    DRIVER_REMOVED: 'driver_removed',
    SCHEDULED: 'Scheduled',
  };
  merchantList = [];
  drivers:any = [];
  allVehiclesFB:any = [];

  geoFire: GeoFire;
  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    public global: GlobalService,
    private db: AngularFireDatabase,
    public datePipe: DatePipe,
    public dialog: MatDialog,
    private mapsAPILoader: MapsAPILoader,
    ) {
    Object.freeze(this.ORDER_STATUSES);
    this.geoFire = new GeoFire(this.db.database.ref('two_wheelers'));
  }

  GetModules () {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post( environment.serverAPI + 'user/GetModules', {'user_id': sessionStorage.getItem('userId')}, {headers: headers});
  }

  openSnackBar(message: string, action: string = 'OK',duration = 10000) {
    return this.snackBar.open(message, action, {
      duration
    });
  }

  getWeekDay(index) {
    //Create an array containing each day, starting with Sunday.
    var weekdays = new Array(
      "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday",
    );
    
    //Return the element that corresponds to that index.
    return weekdays[index];
  }
  getDaysOfWeek(){
    return ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday",];
  }
  itemDetailsTemplate(data, quanityArr, todayOnly = false, showDayName = true, whichDay?:number, hasQtyInSameObj?: boolean) {
    whichDay = whichDay || new Date().getDay();
    if (data && data.indexOf('[') == 0) {
      if (hasQtyInSameObj){
        let dataAndQty = this.splitDataAndQty(data);
        data = dataAndQty['data'];
        quanityArr = dataAndQty['qty'];
      }
      quanityArr = quanityArr || "[]";
      try {
        data = JSON.parse(data);
        let quantityList = JSON.parse(quanityArr);
        if (data.length != 0) {

          data = data.map((dayData, i) => {
            let day = this.getWeekDay(i);
            if (dayData.length == 0) return '';
            let dayName = '<div class="item_details_container"><h4 class="pt-1 bold"><b>' + day + '</b></h4>';
            let string = '' ;
            if(showDayName){
              string += dayName;
            }
            
            let qty = quantityList[i];
            string += '<p>';
            for (let index = 0; index < dayData.length; index++) {
              const item = dayData[index];
              string += '<li>';
              if(qty){
                string += `${item} - ${qty[index]}`;
                // string += `${item}(${qty[index]})`;
              } else{
                string += `${item}`;
              }
              if (index != dayData.length - 1) {
                // string += ', '
              }
              string += '</li>';
            }
              string += '</p></div>';
              return string;

          }
          )
        }
        if (todayOnly){
          let todayIndex;
          var n = whichDay;
          if(n == 0){
            todayIndex = 6;
          } else {
            todayIndex = n - 1;
          }
          return data[todayIndex];
        }
        data = data.filter((el) => el.length);
        data = data.join('')//.replace(/,/g, '');
      } catch (error) {
        // data = '';
      }
    } 
    return data;
  }
  splitDataAndQty(data: any) {
    let returnData = [];
    try {

      let indexOfDataEnd = this.nthIndex(data, ']', 8);
      returnData['data'] = data.substr(0, indexOfDataEnd+1);
      returnData['qty'] = data.slice(indexOfDataEnd+2, -1)
    } catch(e){
      console.error(e);
      returnData['data'] = data
    }
    return returnData;
  }

  nthIndex(str, pat, n) {
    var L = str.length, i = -1;
    while (n-- && i++ < L) {
      i = str.indexOf(pat, i);
      if (i < 0) break;
    }
    return i;
  }

  parseJSON = (obj) =>{
    return Function('"use strict";return (' + obj + ')')();
  } 

  addCSAtoOrder(order_id: any) {
    console.error(`Deprecated: Don't add csa to order from this api`);
    return;
    // If CSA then add csa_id into order
    try {
      if (+sessionStorage.getItem('profileId') == 2) {
        this.global.addCSAToOrder(order_id).subscribe((response: any) => {
          console.log(response)
        }, (error) => {
          console.error(error)
        });
      }
    } catch (e) {
      console.error(e)
    }
  }

  customSortingDataAccessor() {

    return (item: any, path: string): any => {
      return path.split('.')
        .reduce((accumulator: any, key: string) => {
          let returnValue;
          if (accumulator) {
            returnValue = accumulator[key]
          } else {
            returnValue = undefined;
          }
          if (typeof returnValue === 'string') {
            returnValue = returnValue.trim().toLocaleLowerCase()
          }
          return returnValue;
        }, item);
    };
  }

  sortData(sort: Sort, dataSource, roster: string) {
    let data = dataSource.data;
    // const data = this.rosterArray[roster].data.slice();
    if (!sort.active || sort.direction === '') {
      dataSource.data = data;
      return;
    }

    dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a[sort.active], b[sort.active], isAsc);

    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  setMerchantList(merchants){
    this.merchantList = merchants;
  }
  

  getMerchantList(reload:boolean=false){
    return new Promise((resolve,reject)=>{
      if (this.merchantList && this.merchantList.length && !reload){
        return resolve(this.merchantList);
      }
      const data = {
        user_id: sessionStorage.getItem('userId'),
        Offset: 0,
        PageCount: 1000
      };
      this.global.GetAllMerchants(data).subscribe(
        (response: any) => {
          if (response.status === 'success') {
            this.setMerchantList(response.data.Merchants);
            return resolve(response.data.Merchants);

          }
        },
        (error) => {
          if (error.error.status === 'failure') {
            this.openSnackBar(error.error.data.Message, 'Close');
            return resolve(this.merchantList);
          }
        },)

    })

  }

  markAllAsTouched(form: FormGroup) {
    var controls = Object.values(form.controls);
    controls.forEach((c: any) => {

      c.markAsTouched()
      if (c.controls) {
        this.markAllAsTouched(c)
      }
    });
  }

  markAllAsDirty(form: FormGroup) {
    var controls = Object.values(form.controls);
    controls.forEach((c: any) => {

      c.markAsDirty()
      if (c.controls) {
        this.markAllAsDirty(c)
      }
    });
  }

  markAllAsUntouched(form: FormGroup) {
    var controls = Object.values(form.controls);
    controls.forEach((c: any) => {

      c.markAsUntouched()
      if (c.controls) {
        this.markAllAsUntouched(c)
      }
    });
  }

  mergeItemQuantities(details: string): string {
    try {
      let t2 = details.split(',');
      let t3 = {}
      t2.forEach((item, i) => {
        console.log(item);
        // https://stackoverflow.com/a/49042548/6613333
        // Split on last occurence of dash
        let split = item.match(/(.*)\-(.*)/);
        // let split = item.split('-');
        let name = split[1].trim();
        let qty = parseInt(split[2]);
        if (!t3.hasOwnProperty(name)) {
          t3[name] = qty
        } else {
          t3[name] += qty
        }
      });
      var t4 = [];
      for (let item in t3) {
        if (t3.hasOwnProperty(item)) {
          t4.push(`${item} - ${t3[item]}`)
        }
      }
      details = t4.join('\n');
      return details;

    } catch (error) {
      console.error(error);
      return details;
    }
  }

  saveToLocalStorage(key:string,data:any){
    try {
    if(typeof data == 'object'){
      data = JSON.stringify(data);
    }
    localStorage.setItem(key,data);
      
    } catch (error) {
      console.error('Unable to save data to localstorage.');
      console.log({key,data});
    }
  }
  removeFromLocalStorage(key:string){
    localStorage.removeItem(key);
  }

  getFromLocalStorage(key:string){
    let data = localStorage.getItem(key);
    let returnData;
    try {
      if(!null){
        returnData = JSON.parse(data);
      }
    } catch (error) {
      console.error(error);
      returnData = data;
    }
    return returnData;
  }

  getGeoFireQuery(params){
    let {
      lat,
      long,
      radius = 100,
    } = params;
    return this.geoFire.query({
      center: [lat, long],
      radius
    });
  }

  getFromSessionStorage(key: string) {
    let value = '';
    value = sessionStorage.getItem(key);
    return value;
  }

  openDialog(params:any= {}) {
    let {
      width = '400px',
      title,
      text = 'Do you want to continue?',
      method,
      
    } = params; 
    let data: any = {};
    data.confirmationTitle = title;
    data.confirmationText = text;
    data.confirmationMethod = method;

    const dialogRef = this.dialog.open(AppSharedConfirmationComponent, {
      width,
      data: { confirmationTitle: data.confirmationTitle, confirmationText: data.confirmationText, confirmationMethod: data.confirmationMethod, confirmationProceed: true }
    });
    return dialogRef.afterClosed();
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
      (el as HTMLElement).focus()
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('textarea.ng-invalid:not([type="hidden"]), input.ng-invalid:not([type="hidden"]), select.ng-invalid:not([type="hidden"])');
    this.scrollTo(firstElementWithError);
  }

  async scrollIfFormHasErrors(form: FormGroup): Promise<any> {
    await form.invalid;
    this.scrollToError();
  }


  uniqueArray(a) {
    return a
  }

  uniqueArrayByKey(myArr,key) {
       let newArr = myArr.filter((a,i)=>{
      let index = myArr.findIndex(el=>el[key] === a[key])
      return index === i;
    })
    return newArr;
  }

  async geocode(data: any) {
    return new Promise(async (resolve, reject) => {
      this.lastCount++;
      let currentTimestamp = new Date().getTime();
      let time = currentTimestamp - this.lastTimestamp;

      time = time * 1000 * Math.random() * this.lastCount;
      if (time > 10000 || time < 2000) {
        time = 1000 * (Math.floor(Math.random() * 10) + 2)
      }
      if (this.lastTimestamp + 3 < currentTimestamp) {
        this.lastCount = 1;
      }
      this.lastTimestamp = currentTimestamp;
      let geocoder = new google.maps.Geocoder();
      let latlng = new google.maps.LatLng(...data);
      let request = { latLng: latlng };
      // This function has random delays to decrease the limit of calling the Google api to prevent OVER_QUERY_LIMIT error
      await this.delay(time);
      return geocoder.geocode(request,
        (results, status) => {
          console.log("TCL: GetWhizzers -> results, status", results, status)
          return resolve(results)
        }
      )
    })
  }
  lastCount = 0;
  lastTimestamp = 0;
  delay = ms => new Promise(res => setTimeout(res, ms));

  stripHtml(html: string) {
    let newStr = html.replace(/(<([^>]+)>)/ig, "");
    return newStr;
  }

  isCSA() {
    return sessionStorage.getItem('profileId') == '2';
  }

  getDaysArray(start, end) {
    try {
      start = new Date(start);
      end = new Date(end);
      for (var arr = [], dt = start; dt <= end; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt).toISOString().slice(0, 10));
      }
      return arr;
    } catch (error) {
      console.log("TCL: getDaysArray -> error", error)
      return [];
    }
  };

  getAllDatesOfMonth(date) {
    const mDate = moment(date).startOf('month');
    const daysCount = mDate.daysInMonth();
    return Array(daysCount).fill(null).map((v,index)=>{
        const addDays = index === 0 ? 0 : 1;
        return mDate.add(addDays, 'days').format('YYYY-MM-DD');
    });
}

  getCsaId() {
    return sessionStorage.getItem('userId');
  }
  
  getUserId() {
    return sessionStorage.getItem('userId');
  }
  openSelectRefundTypePopup(order: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!order) {
        return reject('Order not found')
      }
      if (order.payment_status == 'Completed') {

        let data: any = {};
        let refund_modes = [];
        if (order.payment_mode == 'whizzy_credits') {
          refund_modes = [
            { name: 'Whizzy Credits', value: 'credits' },
          ];
        }
        if (order.payment_mode && order.payment_mode.toLowerCase() == 'razorpay') {
          refund_modes = [
            { name: 'Bank', value: 'bank' },
            { name: 'Whizzy Credits', value: 'credits' },
          ];
        }
        data.order_id = order.order_id;
        data.refund_modes = refund_modes;
        data.view = 'order_refund_to_options';
        const dialogRef = this.dialog.open(InputPopupComponent, {
          width: '350px',
          data,
        });

        return dialogRef.afterClosed().subscribe(async returnData => {
          console.log(returnData);
          if (!returnData) {
            return reject();
            ;
          }
          if (!returnData.refund_mode) {
            this.openSnackBar('Refund mode not found');
            return reject('Refund mode not found');
          }
          return resolve(returnData)
        })
      }
    })
  }

  // Preserve original property order
  // https://stackoverflow.com/a/52794221/6613333
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  async getAllDrivers() {
    return new Promise(async (resolve, reject) => {
      if(this.drivers.length) {
        return resolve(this.drivers)
      }
      try {
        let resp: any = await this.global.getAllDrivers().toPromise();
        console.log(resp);
        if (resp.status) {
          this.drivers = resp.data.Drivers;
          this.drivers.forEach(a => {
            a['name_mobile'] = `${a.driver_name} (${a.driver_mobile})`;
          });
          return resolve(this.drivers)
        } else {
          return reject(resp);
        }
      } catch (error) {
        return reject(error);
        console.log("TCL: getAllDrivers -> error", error)
      } finally {
      }

    })
  }

  async getAllVehiclesFB() {
    return new Promise(async (resolve, reject) => {
      if(this.allVehiclesFB.length) {
        return resolve(this.allVehiclesFB)
      }
      try {
        let data  = await this.db.object('driver_vehicles_new').valueChanges().pipe(take(1)).toPromise();
        console.log("TCL: getAllVehiclesFB -> data", data)
        if(data) {
          this.allVehiclesFB = data;
        } else {
          this.allVehiclesFB = [];
        }
        return resolve(this.allVehiclesFB)
      } catch (error) {
        console.log("TCL: getAllVehiclesFB -> error", error)
        return reject(error);
      } finally {

      }

    })
  }

  hasRequiredField = (abstractControl: AbstractControl): boolean => {
    if(!abstractControl) return false;
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    if (abstractControl['controls']) {
      for (const controlName in abstractControl['controls']) {
        if (abstractControl['controls'][controlName]) {
          if (this.hasRequiredField(abstractControl['controls'][controlName])) {
            return true;
          }
        }
      }
    }
    return false;
  };

  keyPressMobile(event: any) {
    if (event.charCode !== 0) {
      const pattern = /^[0-9]$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar) && event.charCode !== 13) {
        event.preventDefault();
      }
    }
  }

  getHighchartDefaultOption() {
    let options: Options = {
      chart: {
        type: 'column',
        zoomType: 'xy',
        panning: {
          enabled: true,
        },
        panKey: 'shift',
        events: {
        },
      },
      plotOptions: {
        column: {
          colorByPoint: true,
        },
        bar: {
          colorByPoint: true,
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: [],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      series: [],

    }
    return options;
  }

  returnZero() {
    return 0
  }

  toString(e) {
    return e.toString();
  }

  convertProperty({ items = [], prop = "", fn = (e) => e }) {
    return items.map(item => {
      let newItem = { ...item }
      newItem[prop] = fn(item[prop])
      return newItem;
    })
  }

  isRequiredControl(form: FormGroup, path: string) {
    let returnValue = false;
    if (!(form && path)) return returnValue;
    try {
      let control = form.get(path);
      if (control && control.errors && control.errors.required) {
        returnValue = true;
      }
    } catch (error) {
      console.log("🚀 -> file: shared.service.ts -> line 684 -> SharedService -> isRequiredControl -> error", error.message);
    }
    return returnValue;
  }

  setPagingData(response) {
    let obj = {
      length: response ? response.total_items : [],
      pageSize: response ? response.limit : 10,
      pageIndex: response ? response.page_no - 1 : 1,
      pageSizeOptions: [10, 20, 30, 50, 100],
      showFirstLastButtons: true,
    }
    return obj;
  }

  getPageAndLimitFromPageEvent(pageEvent: PageEvent) {
    let obj = {
      page_no: pageEvent.pageIndex + 1,
      limit: pageEvent.pageSize,
    }

    return obj;
  }

  setServerSideErrors(response, form: FormGroup) {
    try {
      if (!response.status) {
        let { errors } = response.error;
        if (errors && Array.isArray(errors) && errors.length) {
          errors.forEach(item => {
            form.get(item.path.join('.')).setErrors({ serverError: item.message })
          })
        }
        setTimeout(() => {
          this.scrollIfFormHasErrors(form)
        }, 1)
        this.openSnackBar(response.error.responseMessage)
      }
    } catch (error) {
      this.openSnackBar(error.message)
    }
  }

   getValue (obj, path, defaultValue = null) {
    const travel = regexp =>
      String.prototype.split
        .call(path, regexp)
        .filter(Boolean)
        .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
    const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
    return result === undefined || result === obj ? defaultValue : result;
  };

  isUrl(string) {
    return string.indexOf('http') === 0
  }
}
