import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'file-upload-dnd',
  templateUrl: './file-upload-dnd.component.html',
  styles: [
    `
    .file-upload-dnd {
      
    }
    .drag-area {
      width:200px;
      height:200px;
      background: #eee;
      border:1px dashed #ccc;
      display:flex;
    }

    .drag-area img{
      max-width:200px;
      max-height:200px;
    }
    .drag-area img:hover{
      opacity:0.7;
    }
    .preview {
      position:relative;
      align-items:center;
    }
    .preview img {
      object-fit:cover;
    }
    .close-btn {
      right:0;
      position:absolute;
    }

    .drop-zone-active {
      border:3px dashed #eee;
      background: #aaa; 
    }
    `

  ]
})
export class FileUploadDNDComponent implements OnInit {

  public imagePath;
  public message: string;
  @Input() imgURL: any;

  @Output() fileDropped = new EventEmitter();
  @Output() deleteImage = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  preview(files) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    } else {
      this.message = '';
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      this.fileDropped.emit(this.imgURL)
    }
  }

  clearImage(e: Event, file: HTMLInputElement) {
    e.stopPropagation();
    this.imgURL = '';
    this.deleteImage.emit();
    if (file) {
      file.value = '';
    }
  }

  reset() {
    this.imgURL = ''
  }

}
