import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthenticationService } from './authentication.service';
import { Router, NavigationStart, RouterEvent, NavigationEnd, RouteConfigLoadStart, NavigationCancel } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private service: AuthenticationService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {

  
      const profileId = sessionStorage.getItem('profileId');
      let isAdmin = this.service.isAdmin();
      let redirectPath = '/';
      let merchant_type = this.service.decodedToken ? this.service.decodedToken.onboarded_type : null; 

      switch (profileId) {
        case '1':
          redirectPath = '/dashboards';
          break;

        case '2':
          redirectPath = '/dashboards';
          break;

        case '3':
          redirectPath = '/merchant';
          break;

        case '4':
          redirectPath = '/requests/myrequests';
          break;

        case '5':
          redirectPath = '/requests/myrequests';
          break;

        case '6':
          redirectPath = '/requests/myrequests';
          break;

        default:
          redirectPath = '/requests/myrequests';
          break;
      }

      if (state.url == '/credits/give-credits') {
        if (!isAdmin) {
          this.router.navigateByUrl(redirectPath);
        }
        return resolve(isAdmin)
      }

      if (state.url.includes('/club')) {
        if (merchant_type && merchant_type === 'club') {
          return resolve(true)
        }
        return this.router.navigateByUrl(redirectPath);
      }

      if (this.service.isUserLoggedIn()) {

        switch (state.url) {
          case '/home':
            this.router.navigateByUrl(redirectPath);
            resolve(false);
            break;
          case '/authentication/login':
            this.router.navigateByUrl(redirectPath);
            resolve(false);
            break;
          case '/authentication/forgot':
            this.router.navigateByUrl(redirectPath);
            resolve(false);
            break;
          case '/authentication/register':
            this.router.navigateByUrl(redirectPath);
            resolve(false);
            break;
          default:
            resolve(true);
        }
      } else {
        switch (state.url) {
          case '/':
            resolve(true);
            break;
          case '/home':
            resolve(true);
            break;
          case '/authentication/login':
            resolve(true);
            break;
          case '/authentication/forgot':
            resolve(true);
            break;
          case '/authentication/register':
            resolve(true);
            break;
          default: this.router.navigateByUrl('');
            resolve(false);
        }
      }

    });

  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(route, state);
  }

}
