import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styles: [
    `
    table.tdHoverZoom {
      td {
        transition: all .5s ease;

        &:hover {
          font-size: 24px;
          line-height: 24px;
        }

      }
    }
    caption { 
      caption-side: top;
    }
    `
  ]
})
export class TableComponent implements OnInit {

@Output() tdClicked = new EventEmitter<any>();

  @Input() maxheight: number = 500;
  @Input() classes: string;
  @Input() caption: string;
  @Input() isModal: boolean;
  @Input() isVertical: boolean;
  @Input() tdMinWidth: number;
  @Input() rows: any = []
  @Input() columns: any = []


  @Input()
  set allowZoom(value: boolean) {
    this._allow_zoom = value;
    this.classes = `${this.classes} ${this.zoomClass}`
  };

  get allowZoom(): boolean {
    return this._allow_zoom;
  }

  _allow_zoom: boolean;
  zoomClass = 'tdHoverZoom';

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() { 

  }

  onTdClicked(col, row) {
    let data :any = {};
    data.col = col;
    data.row = row;
    this.tdClicked.emit(data);
  }
}
