import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';

export const AppRoutes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomeModule'},
  { path: 'authentication', loadChildren: './authentication/authentication.module#AuthenticationModule'},
  { path: 'dashboards', component: FullComponent, loadChildren: './dashboards/dashboards.module#DashboardsModule'},
  { path: 'merchant', component: FullComponent, loadChildren: './merchant/merchant.module#MerchantModule'},
  { path: 'rosters', component: FullComponent, loadChildren: './rosters/rosters.module#RostersModule'},
  { path: 'requests', component: FullComponent, loadChildren: './requests/requests.module#RequestsModule'},
  { path: 'addressbook', component: FullComponent, loadChildren: './addressbook/addressbook.module#AddressbookModule'},
  { path: 'administration', component: FullComponent, loadChildren: './administration/administration.module#AdministrationModule'},
  { path: 'track', component: FullComponent,  loadChildren: './track/track.module#TrackModule' },
  { path: 'info', component: AppBlankComponent, loadChildren: './info/info.module#InfoModule' },
  { path: 'user', component: FullComponent, loadChildren: './user/user.module#UserModule' },
  { path: 'scheduled-orders', component: FullComponent, loadChildren: './scheduled-orders/scheduled-orders.module#ScheduledOrdersModule' },
  { path: 'driver-tracking', component: FullComponent, loadChildren: './reassignment/reassignment.module#ReassignmentModule' },
  { path: 'reallotment', component: FullComponent, loadChildren: './reallotment/reallotment.module#ReallotmentModule' },
  { path: 'driver-portal', component: FullComponent, loadChildren: './driver-portal/driver-portal.module#DriverPortalModule' },
  // { path: 'merchant-engage', component: FullComponent, loadChildren: './merchant/merchant.module#MerchantModule' },
  { path: 'summary', component: FullComponent, loadChildren: './summary/summary.module#SummaryModule' },
  { path: 'orders', component: FullComponent, loadChildren: './orders-management/orders-management.module#OrdersManagementModule' },
  { path: 'csa', component: FullComponent, loadChildren: './csa/csa.module#CsaModule' },
  { path: 'homechef', component: FullComponent, loadChildren: './homechef/homechef.module#HomechefModule' },
  { path: 'credits', component: FullComponent, loadChildren: './credits/credits.module#CreditsModule' },
  { path: "dashboards", component: FullComponent, loadChildren: "./wow-panel/wow-panel.module#WowPanelModule" },
  { path: "ev", component: FullComponent, loadChildren: "./ev/ev.module#EVModule" },
  { path: "vendor", component: FullComponent, loadChildren: "./vendor-management/vendor-management.module#VendorManagementModule" },
  { path: 'register',component:AppBlankComponent, loadChildren: './Vendor-Link/Vendor-Link.module#VendorLinkModule'},
  { path: '**', redirectTo: '/home' },
];